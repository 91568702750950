import React, { useEffect, useState } from "react";
import classes from "./ChangeBookingDate.module.css";
import { Input, Modal, message } from "antd";
import useWindowWidth from "helpers/useWindowWidth";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";
import CalendarSelection from "components/page-content/venue-content/calendar-selection/CalendarSelection";
import { useQuery } from "@tanstack/react-query";
import { VenueDetailDataKey } from "helpers/queriesDataKeys";
import Button from "components/UI/button/Button";

function ChangeBookingDate({
  visible,
  setVisible,
  onCancel,
  onSubmit,
  bookingId,
  itemId,
  fetchBookings,
  venueData,
  bookingData,
  setRemarks,
  remarks,
  intervals,
  setIntervals,
  selectedDate,
  setSelectedDate
}) {
  const width = useWindowWidth();

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);
  const [selectedInterval, setSelectedInterval] = useState("");
  const [dayValue, setDayValue] = useState("");
  
  
  const [selectedSlotPrice, setSelectedSlotPrice] = useState(0);
  const [bookingLoading, setBookingLoading] = useState(false);
  

  console.log("selected interval", selectedInterval, selectedDate);

  const onChangeDate = async () => {
    setBookingLoading(true)
    const requestBody = {
      bookingId: bookingData?.id,
      bookingItemId: bookingData?.itemId,
      requestedNewDate: selectedDate,
      
      remarks: remarks,
    };

    if(venueData?.slotType != "Slot"){
      requestBody.requestedStart = String(selectedInterval?.start);
      requestBody.requestedEnd = String(selectedInterval?.end);
      requestBody.requestedSlotNo = Number(selectedInterval?.slotNo);
    }

    // const response = await fetch(`${API_BASE_URL}booking-date-change-request`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${user.sessionToken}`,
    //   },
    //   body: JSON.stringify(requestBody), // Convert request body to JSON string
    // });

    const response = await fetch(`${API_BASE_URL}booking-date-change-request`,{
      method: 'POST',
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify(requestBody), // Convert request body to JSON string
    })
  .then(response => {
    if (!response.ok) {
      // If response status is not OK (not 200-299 range), handle the error
      return response.json().then(errorData => {
        // Handle specific error message
        throw new Error(errorData.message || "Unknown error occurred");
      });
    }
    return response.json();
  })
  .then(data => {
    // Handle successful data response here
    console.log('Success:', data);
    message.success("Date Change Request Submitted Successfully");
    setVisible(false)
  })
  .catch(error => {
    // Handle error here
    console.error('Error:', error.message);
    message.error(`${error.message}`);
  });
    
    // const data = await response.json()
    // console.log('response istr', data)
    // if(data?.error){
    //   message.error(data.message)
      
    // }else{
    //   message.success("Date Changed Successfully")
    // }
    setBookingLoading(false)
  };

  console.log("venue data isre", venueData, bookingData);

  const cancelOrder = async () => {
    setLoading(true);

    const requestBody = {
      itemId: itemId,
      status: "Cancelled",
      // review: remarks,
    };

    const response = await fetch(
      `${API_BASE_URL}booking/${bookingId}/updateStatus/admin-vendor`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(requestBody), // Convert request body to JSON string
      }
    );

    const data = await response.json();

    if (data?.success) {
      message.success("Booking cancelled Successfully");
      setVisible(false);
      setLoading(false);
      fetchBookings();
    } else {
      setLoading(false);
    }

    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  };

  return (
    <Modal
      className="review-modal"
      visible={visible}
      // title="Available Dates"
      onCancel={onCancel}
      footer={null}
      width={width > 490 ? 740 : "90vw"}
      closable={false}

      //   footer={[
      //     <Button key="cancel" onClick={onCancel}>
      //       Cancel
      //     </Button>,
      //     <Button key="submit" type="primary" onClick={handleSubmit}>
      //       Submit
      //     </Button>,
      //   ]}
    >
      <div className={classes.modalWrapper}>
        <h1>Available Dates</h1>
        <CalendarSelection
          details={venueData} //venuedata array
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
          dayValue={dayValue}
          setDayValue={setDayValue}
          intervals={intervals}
          setIntervals={setIntervals}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          mode={venueData?.slotType}
          selectedSlotPrice={selectedSlotPrice}
          setSelectedSlotPrice={setSelectedSlotPrice}
          venueDetail={venueData} //venuedata array
        />
        <Input.TextArea
          onChange={(e) => setRemarks(e.target.value)}
          rows={4}
          placeholder="Remarks..."
          value={remarks}
        />
        <Button
          option="colored"
          type="submit"
          loading={bookingLoading}
          disabled={bookingLoading}
          className={classes.changeDate}
          onClick={onChangeDate}
        >
          Change Date
        </Button>
      </div>
    </Modal>
  );
}

export default ChangeBookingDate;

import React from "react";
import classes from "./PackageCard.module.css";

function PackageCard({ image, title, review, city, state, price, strikePrice, details }) {


  let detailsArray = [];
  if(details.length>3){
    detailsArray = [...details.slice(0,3),'...'];
  }else{
    detailsArray = details;
  }

    return (
    <div className="event-slider">
      <div className={classes.eventsOuter}>
        <img className={classes.heartIcon} src="/img/icons/heart.svg" />
        <img className={classes.titleImage} src={image} />
        <div className={classes.titleSection}>
          <h2>{title}</h2>
          
        </div>

        <div className={classes.locationSection}>
          <img src="/img/packages/location_icon.svg" />
          <h3>
            {city} , {state}
          </h3>
        </div>
        <div className = {classes.detailsSection}>
            {detailsArray.map((item)=>(
                <div className = {classes.detailsWrapper}>
                 <p>{item}</p>
                </div>
            ))}
        </div>
        <div className={classes.priceSection}>
          <h2>₹ {price.toLocaleString('en-IN')}</h2>
          <h3>₹ {strikePrice.toLocaleString('en-IN')}</h3>
        </div>
      </div>
    </div>
  );
}

export default PackageCard;

import React, { useEffect, useRef, useState } from "react";
import classes from "./PolicyDetail.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdjustFontSize from "./AdjustFontSize";
import useWindowWidth from "helpers/useWindowWidth";
import {
  API_BASE_URL,
  LIVE_URL,
  REACT_APP_DEV_MODE,
  TEST_URL,
} from "configs/AppConfig";
import { PolicyDataKey } from "helpers/queriesDataKeys";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { message, notification } from "antd";

const PolicyDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const { infoSlug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const infoDetail = [];
  // const [infoDetail, setInfoDetail] = useState();
  // console.log("info detail 222", infoDetail);

  const targetRef = useRef(null);

  // const { privacyPolicies } = useSelector((state) => state.constants);

  const [responsiveHtml, setResponsiveHtml] = useState("");
  const [title, setTitle] = useState("");

  const fetchPolicy = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}information-policy/getBySlug/${infoSlug}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      setTitle(data?.data?.data?.title);
      console.log("policy data", data);
      setResponsiveHtml(AdjustFontSize(data?.data?.data?.content));
      setIsLoading(false);
    } catch (error) {
      message.error(error?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPolicy();
  }, [window.location.pathname]);

  return (
    <div className="privacy-content">
      {isLoading && <LoadingSpinner />}
      {!isLoading && infoDetail && (
        <div className={classes.container} ref={targetRef}>
          <div className={classes.privacypolicy}>
            {infoDetail && (
              <>
                <div className={classes.policyheader}>
                  <h1 className={classes.policytitle}>{title}</h1>
                </div>
                <div className={classes.policycontent}>
                  <div
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: responsiveHtml,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PolicyDetail;

import { forwardRef } from 'react';

import SelectInput from './SelectInput';

import classes from './InputWithLabel.module.css';

const InputWithLabel = forwardRef(
  (
    {
      labelText,
      name,
      type = 'text',
      value,
      onChange,
      onBlur,
      icon: Icon,
      className,
      options,
      placeHolder,
      register,
      validation,
      disabled,
      togglePassword,
      ...props
    },
    ref
  ) => {
    // render input component if there is no options array provided
    // else reneder select component and map the options in it

    const reg = register ? { ...register(name, { ...validation }) } : null;

    const inputComponent = !options ? (
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        placeholder={placeHolder ? placeHolder : ' '}
        {...reg}
        disabled={disabled}
        {...props}
      />
    ) : (
      <SelectInput
        ref={ref}
        options={options}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeHolder ? placeHolder : ' '}
        register={register}
        validation={validation}
      />
    );
    return (
      <div className={`${classes.inputContainer} ${className}`}>
        {inputComponent}
        {labelText && <label htmlFor={name}> {labelText} </label>}
        {/* {Icon && <Icon className={classes.icon} onClick={togglePassword}/>} */}
        {Icon && (
          <div onClick={togglePassword}>
            <Icon className={classes.icon} />
          </div>
        )}
      </div>
    );
  }
);

export default InputWithLabel;

import React, { useEffect, useRef, useState } from "react";
import classes from "./PackageFilter.module.css";
import { Slider, InputNumber, Row, Col, Select, Button } from "antd";
import { Scrollbars } from 'react-custom-scrollbars';

function PackageFilters({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  locArray,
  setLocArray,
  eventArray,
  setEventArray,
  priceValue,
  setPriceValue,
  ratingArray,
  setRatingArray,
  proximityData,
  setProximitydata,
  isVisible,
  setIsVisible,
  capacityData,
  setCapacityData,
}) {
  const { Option } = Select;

  const myDivRef = useRef(null);

  const filtersdata = ["Venue", "Wedding", "Within 5 Km"];
  const locationData = [
    "Kerala",
    "Bengaluru, Karnataka",
    "Goa",
    "Ernakulam , Kerala",
    "Kerala",
    "Bengaluru, Karnataka",
    "Goa",
    "Ernakulam , Kerala",
  ];
  const proximityValue = ["Within 5 km", "Within 10 km", "Within 30 km"];
  const capacityValue = ["200", "300", "500"];
  const eventData = [
    "Wedding",
    "Birth days",
    "Party",
    "Anniversary",
    "Baptism",
    "Small Party",
    "Birth days",
    "Party",
    "Anniversary",
    "Baptism",
    "Small Party",
  ];
  const priceData = [
    "Under ₹400 per plate",
    "₹400 - ₹800 per plate",
    "₹800 to ₹1000 per plate",
    "₹1000 to ₹1200 per plate",
  ];
  const ratingData = [
    "4 Star & above",
    "3 Star & above",
    "2 Star & above",
    "1 Star & above",
  ];

  const [minPrice, setMinPrice] = useState(100000);
  const [maxPrice, setMaxPrice] = useState(500000);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [className, setClassName] = useState("filterOuter");

  useEffect(() => {
    if (
      locArray.length > 0 ||
      eventArray.length > 0 ||
      ratingArray.length > 0 ||
      // priceArray.length > 0 ||
      proximityData ||
      capacityData ||
      priceValue
    ) {
      if (proximityData != null && priceValue !== null && capacityData!==null) {
        console.log("entered here");
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          proximityData,
          priceValue,
          capacityData,
        ]);
      } else if (proximityData === null && capacityData ==null && priceValue !== null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          priceValue,
        ]);
      }else if (proximityData === null && capacityData !==null && priceValue !== null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          priceValue,
          capacityData,
        ]);
      }
       else if (proximityData !== null && capacityData !== null && priceValue === null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          proximityData,
          capacityData,
        ]);
      }
      else if (proximityData !== null && capacityData === null && priceValue !== null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          proximityData,
          priceValue,
        ]);
      } 
      else if (proximityData === null && capacityData !== null && priceValue === null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          capacityData,
        ]);
      }
      else if (proximityData !== null && capacityData === null && priceValue === null) {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
          proximityData,
        ]);
      }else {
        setAppliedFilterData([
          ...eventArray,
          ...locArray,
          ...ratingArray,
          // ...priceArray,
        ]);
      }
    }
  }, [locArray, eventArray, proximityData, ratingArray, priceValue, capacityData]);

  //   useEffect(() => {
  //     if (eventArray.length > 0) {
  //       setAppliedFilterData([...appliedFilterData, ...eventArray]);
  //     }
  //   }, [eventArray]);

  //   useEffect(() => {
  //     if (proximityData != null) {
  //       setAppliedFilterData([...appliedFilterData, proximityData]);
  //     }
  //   }, [proximityData]);

  const sliderStyle = {};

  const railStyle = {
    backgroundColor: "blue", // Set the color of the track
  };

  const handleStyle = {
    borderColor: "blue", // Set the color of the handle
  };

  const handleMinPriceChange = (value) => {
    console.log("min price", value);
    setMinPrice(value);
  };

  const handleMaxPriceChange = (value) => {
    setMaxPrice(value);
  };

  const handleSliderChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  function handleChange(value) {
    console.log(`Selected: ${value}`);
    setProximitydata(value);
  }

  function handleChangeCapacity(value) {
    console.log(`Selected: ${value}`);
    setCapacityData(value);
  }

  const filterhandler = (item) => {
    const index = locArray.find((data) => item == data);
    if (!index) {
      setLocArray([...locArray, item]);
    } else {
      setLocArray(locArray.filter((card) => card != item));
    }
  };

  const eventFilterHandler = (item) => {
    const index = eventArray.find((data) => item == data);
    if (!index) {
      setEventArray([...eventArray, item]);
    } else {
      setEventArray(eventArray.filter((card) => card != item));
    }
  };

  const ratingFilterHandler = (item) => {
    const index = ratingArray.find((data) => item == data);
    if (!index) {
      setRatingArray([...ratingArray, item]);
    } else {
      setRatingArray(ratingArray.filter((card) => card != item));
    }
  };

  const priceFilterHandler = (item) => {
    // const index = priceArray.find((data) => item == data);
    // if (!index) {
    //   setPriceArray([...priceArray, item]);
    // } else {
    //   setPriceArray(priceArray.filter((card) => card != item));
    // }
    setPriceValue(item);
  };

  // const removeFilter = (item) => {
  //   const catFinder = locArray.find((data) => item == data);
  //   const proxFinder = proximityData == item;
  //   const eventFinder = eventArray.find((data) => item == data);
  //   const ratingFinder = ratingArray.find((data) => item == data);
  //   const priceFinder = priceValue == item;

  //   console.log("remove data", catFinder, proxFinder, item, locArray);

  //   if (catFinder) {
  //     setLocArray(locArray.filter((data) => item != data));
  //     setAppliedFilterData(appliedFilterData.filter((card) => card != item));
  //   } else if (proxFinder) {
  //     setProximitydata(null);
  //     setAppliedFilterData(appliedFilterData.filter((data) => data != item));
  //   } else if (eventFinder) {
  //     setEventArray(eventArray.filter((data) => item != data));
  //     setAppliedFilterData(appliedFilterData.filter((card) => card != item));
  //   } else if (ratingFinder) {
  //     setRatingArray(ratingArray.filter((data) => item != data));
  //     setAppliedFilterData(appliedFilterData.filter((card) => card != item));
  //   } else if (priceFinder) {
  //     // setPriceArray(priceArray.filter((data) => item != data));
  //     // setAppliedFilterData(appliedFilterData.filter((card) => card != item));
  //     setPriceValue(null);
  //     setAppliedFilterData(appliedFilterData.filter((card) => card != item));
  //   }
  // };

  const clearAppliedFilter = () => {
    setAppliedFilterData([]);
    setLocArray([]);
    setEventArray([]);
    setProximitydata(null);
    setRatingArray([]);
    setPriceValue();
    setCapacityData(null);
  };

  console.log("cat array", locArray, proximityData);
  console.log("applied filter", appliedFilterData);

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/img/dropdown-arrow.svg"
      style={{ width: "24px", height: "24px" }}
    /> // Customize the arrow color as needed
  );

  const customArrow2 = (
    <img
      src="/img/location-icon.svg"
      style={{ width: "11px", height: "15px" }}
    /> // Customize the arrow color as needed
  );


  // const getClassName = () => {
  //   if (windowWidth < 900) {
  //     return isVisible ? 'filterOuter-slider' : 'filterOuter';
  //   } else {
  //     return 'filterOuter';
  //   }
  // };

  useEffect(() => {
    console.log("entered here");
    if (isVisible) {
      setClassName("filterOuterSlider");
    } else {
      setClassName("filterOuter");
    }
  }, [isVisible]);

  console.log("class name", className);

  return (
    <div ref={myDivRef} className={className} style={{height: "1210px"}}>
      <div className={classes.closeSymbolDiv}>
        <img
          onClick={() => setIsVisible(!isVisible)}
          src="/img/icons/close-icon-red.svg"
        />
      </div>
      <div className={classes.appliedFilters}>
        <div className={classes.filterTitle}>
          <h1>Applied Filters</h1>
          <h4 onClick={clearAppliedFilter}>CLEAR</h4>
        </div>
        <div className={classes.filterFlex}>
          {appliedFilterData?.map((item) => (
            <div className={classes.filterContainer}>
              <h5>{item}</h5>
              <img
                onClick={() => removeFilter(item)}
                src="/img/icons/crossmark.svg"
              />
            </div>
          ))}
        </div>
      </div>
     
      <div className={classes.categoriesFilter}>
        <h1>Location</h1>
        <Select
          suffixIcon={customArrow2}
          placeholder={<span style={placeholderStyle}>Search by Location</span>}
            showSearch
          style={{ width: "100%", cursor: "pointer", height: "43px", marginBottom: "18px" }}
          optionFilterProp="children"
          onChange={handleChange}
          value={proximityData}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {/* {proximityValue.map((item) => (
            <Option value={item}>{item}</Option>
          ))} */}
        </Select>
        <Scrollbars
      style={{ width: '100%', height: 96 }}
      autoHide  // This prop hides the scrollbar when not scrolling
    >
        <div className={classes.categoryCheck}>
          {locationData.map((item) => (
            <div className={classes.categoryDiv}>
              <img
                src={
                  locArray.find((data) => {
                    console.log("item values", item, data);
                    return item == data;
                  })
                    ? "/img/icons/checkboxRed.svg"
                    : "/img/icons/checkboxEmpty.svg"
                }
                onClick={() => filterhandler(item)}
              />
              <h4>{item}</h4>
            </div>
          ))}
        </div>
        </Scrollbars>
      </div>
      
      <div className={classes.proximityFilter}>
        <h2>Proximity</h2>
        <Select
          suffixIcon={customArrow}
          placeholder={<span style={placeholderStyle}>Select Proximity</span>}
          //   showSearch
          style={{ width: "100%", cursor: "pointer", height: "43px" }}
          optionFilterProp="children"
          onChange={handleChange}
          value={proximityData}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {proximityValue.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
      </div>
      <div className={classes.proximityFilter}>
        <h2>Capacity</h2>
        <Select
          suffixIcon={customArrow}
          placeholder={<span style={placeholderStyle}>Select Capacity</span>}
          //   showSearch
          style={{ width: "100%", cursor: "pointer", height: "43px" }}
          optionFilterProp="children"
          onChange={handleChangeCapacity}
          value={capacityData}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {capacityValue.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
      </div>
      <div className={classes.eventFilter}>
        <h2>Event type</h2>
        <Scrollbars
      style={{ width: '100%', height: 122 }}
      autoHide  // This prop hides the scrollbar when not scrolling
    >
        <div className={classes.categoryCheck}>
          {eventData.map((item) => (
            <div className={classes.categoryDiv}>
              <img
                src={
                  eventArray.find((data) => {
                    console.log("item values", item, data);
                    return item == data;
                  })
                    ? "/img/icons/checkboxRed.svg"
                    : "/img/icons/checkboxEmpty.svg"
                }
                onClick={() => eventFilterHandler(item)}
              />
              <h4>{item}</h4>
            </div>
          ))}
        </div>
        </Scrollbars>
      </div>

      <div className={classes.priceFilter}>
        <div className={classes.filterTitle}>
          <h1>Price range</h1>
          <h4
            onClick={() => {
              setMinPrice("");
              setMaxPrice("");
            }}
          >
            CLEAR
          </h4>
        </div>

        <Slider
          range
          min={0}
          max={1000000}
          step={10000}
          value={[minPrice, maxPrice]}
          onChange={handleSliderChange}
          railStyle={railStyle}
          handleStyle={handleStyle}
        />
        <div className="input-flex">
          <div className={classes.inputFlex}>
            <InputNumber
              min={0}
              max={100000000}
              style={{ margin: "0 16px", width: "50%" }}
              value={minPrice}
              onChange={handleMinPriceChange}
              placeholder="Min"
            />

            <InputNumber
              min={0}
              max={100000000}
              style={{ margin: "0 16px", width: "50%" }}
              value={maxPrice}
              onChange={handleMaxPriceChange}
              placeholder="Max"
            />
          </div>
        </div>
        <Button className={classes.submitButton}>Submit</Button>
        <div className={classes.categoryCheck}>
          {priceData.map((item) => (
            <div className={classes.categoryDiv}>
              <img
                src={
                  priceValue == item
                    ? "/img/icons/radioRed.svg"
                    : "/img/icons/radioCircle.svg"
                }
                onClick={() => priceFilterHandler(item)}
              />
              <h4>{item}</h4>
            </div>
          ))}
        </div>
      </div>
      {/* <div className={classes.ratingFilter}>
        <h2>Rating</h2>
        <div className={classes.categoryCheck}>
          {ratingData.map((item) => (
            <div className={classes.categoryDiv}>
              <img
                src={
                  ratingArray.find((data) => {
                    console.log("item values", item, data);
                    return item == data;
                  })
                    ? "/img/icons/checkboxRed.svg"
                    : "/img/icons/checkboxEmpty.svg"
                }
                onClick={() => ratingFilterHandler(item)}
              />
              <h4>{item}</h4>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default PackageFilters;

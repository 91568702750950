export const emailIsValid = (email) => {
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return email.match(validEmailRegex);
};

export const passwordIsValid = (password) => {
  const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})'
  );

  return password.match(passwordRegex);
};

export const confirmPasswordIsValid = (confirmPassword, password) => {
  return confirmPassword === password;
};

export const notEmptyIsValid = (text) => {
  if (!text) {
    return false;
  }
  return text.trim().length > 0;
};

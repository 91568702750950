import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";
import "./WeddingPagination.css";

const WeddingPagination = ({currentPage, setCurrentPage, totalItemsCount, itemsCountPerPage}) => {
  const [activePage, setActivePage] = useState(currentPage);
  const pageref = useRef();
  let pagesToDisplay;

  console.log('fixed values', totalItemsCount, itemsCountPerPage)



  if(window.innerWidth>750){
    pagesToDisplay = 8;
  }else if(window.innerWidth>470 && window.innerWidth<=750){
    pagesToDisplay = 4;
  }else{
    pagesToDisplay = 2;
  }

  useEffect(()=>{
    setCurrentPage(activePage)
  },[activePage])

  

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  };

  console.log("page ref", pageref);

  const handlePrevButtonClick = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextButtonClick = () => {
    // Assuming a total of 10 pages, you can adjust this based on your actual totalItemsCount
    const totalPages = Math.ceil(totalItemsCount/itemsCountPerPage);

    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  return (
    <div className="custom-pagination-wedding">
      <p
        onClick={handlePrevButtonClick}
        className="prev-button"
      >
        Prev
      </p>
      <Pagination
        ref={pageref}
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pagesToDisplay}
        // pageRangeDisplayed={window.innerWidth>500 ? 5: (window.innerWidth<300 ? 2 : 3)}
        onChange={handlePageChange}
      />
      <p
        onClick={handleNextButtonClick}
        className="next-button"
      >
        Next
      </p>
    </div>
  );
};

export default WeddingPagination;

import React, { useState } from "react";
import classes from "./CancelOrder.module.css";
import { Button, Modal, message } from "antd";
import useWindowWidth from "helpers/useWindowWidth";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";

function CancelOrder({
  visible,
  setVisible,
  onCancel,
  onSubmit,
  bookingId,
  itemId,
  fetchBookings,
}) {
  const width = useWindowWidth();

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);

  const cancelOrder = async () => {
    setLoading(true);

    const requestBody = {
      itemId: itemId,
      status: "Cancelled",
      // review: remarks,
    };

    const response = await fetch(
      `${API_BASE_URL}booking/${bookingId}/updateStatus/admin-vendor`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(requestBody), // Convert request body to JSON string
      }
    );

    const data = await response.json();

    if (data?.success) {
      message.success("Booking cancelled Successfully");
      setVisible(false);
      setLoading(false);
      fetchBookings();
    }else{
      setLoading(false);
    }

    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  };

  return (
    <Modal
      className="review-modal"
      visible={visible}
      //   title="Create a Review"
      onCancel={onCancel}
      footer={null}
      width={width > 490 ? 440 : "90vw"}
      closable={false}

      //   footer={[
      //     <Button key="cancel" onClick={onCancel}>
      //       Cancel
      //     </Button>,
      //     <Button key="submit" type="primary" onClick={handleSubmit}>
      //       Submit
      //     </Button>,
      //   ]}
    >
      <div className={classes.modalWrapper}>
        <img src="/img/icons/question-mark.svg" />
        <h1>Cancel Order</h1>
        <h2>
          Are you sure you want to cancel your booking? Your event details may
          be lost.
        </h2>
        <div className={classes.buttonGroup}>
          <Button
            loading={loading}
            onClick={cancelOrder}
            className={classes.confirm}
          >
            Confirm
          </Button>
          <button
            className={classes.noConfirm}
            onClick={() => setVisible(false)}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CancelOrder;

const MailIcon = ({ className }) => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.0117 4.01172V2.5L8 6.26172L1.98828 2.5V4.01172L8 7.73828L14.0117 4.01172ZM14.0117 0.988281C14.4102 0.988281 14.75 1.14062 15.0312 1.44531C15.3359 1.75 15.4883 2.10156 15.4883 2.5V11.5C15.4883 11.8984 15.3359 12.25 15.0312 12.5547C14.75 12.8594 14.4102 13.0117 14.0117 13.0117H1.98828C1.58984 13.0117 1.23828 12.8594 0.933594 12.5547C0.652344 12.25 0.511719 11.8984 0.511719 11.5V2.5C0.511719 2.10156 0.652344 1.75 0.933594 1.44531C1.23828 1.14062 1.58984 0.988281 1.98828 0.988281H14.0117Z'
        fill='#0D0A19'
      />
    </svg>
  );
};

export default MailIcon;

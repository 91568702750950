import React from 'react';

const RightArrowBlack = ({ className, onClick }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <circle opacity="0.6" cx="26" cy="26" r="26" transform="matrix(-1 0 0 1 52 0)" fill="black" />
            <path d="M21.9392 16.4548C21.658 16.7361 21.5 17.1176 21.5 17.5153C21.5 17.9131 21.658 18.2945 21.9392 18.5758L29.3642 26.0008L21.9392 33.4258C21.6659 33.7087 21.5148 34.0876 21.5182 34.4809C21.5216 34.8742 21.6793 35.2504 21.9575 35.5285C22.2356 35.8066 22.6118 35.9644 23.0051 35.9678C23.3984 35.9712 23.7773 35.82 24.0602 35.5468L32.5457 27.0613C32.8269 26.78 32.9849 26.3986 32.9849 26.0008C32.9849 25.6031 32.8269 25.2216 32.5457 24.9403L24.0602 16.4548C23.7789 16.1736 23.3974 16.0156 22.9997 16.0156C22.6019 16.0156 22.2205 16.1736 21.9392 16.4548Z" fill="white" />
        </svg>
    );
};

export default RightArrowBlack;
import React from "react";
import classes from "./PriceDetail.module.css";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import { DatePicker } from "antd";
import CustomDateIcon from "components/UI/icons/CustomDateIcon";
import Button from "components/UI/button/Button";

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const PriceDetail = ({ details }) => {
  return (
    <div className={classes.priceContainer}>
      {/* section 1 */}
      <div className={classes.sectionWrapper}>
        <div className={classes.heading}>Price Details</div>
        <div className={classes.listRow}>
          <h1 className={classes.rowTitle}>Total Price</h1>
          <div className={classes.rowValueWrapper}>
            <h2 className={classes.rowValue}>
              ₹ {details?.price.toLocaleString("en-IN")}
            </h2>
            <h3 className={classes.rowStrikePrice}>
              ₹ {details?.strikePrice.toLocaleString("en-IN")}
            </h3>
          </div>
        </div>
      </div>

      <br className={classes.breakLine} />
      {/* section 3 */}
      <div className={classes.inputSection}>
        <label className={classes.inputLabel}>Event Date</label>
        <DatePicker
          placeholder="Choose Your Event Date"
          format={dateFormatList}
          className="dateInput"
          suffixIcon={<CustomDateIcon />}
        />
        <Button
          option="colored"
          type="submit"
          // loading={isLoading}
          // disabled={isLoading}
          className={classes.cartButton}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
};

export default PriceDetail;

import React from "react";
import BackIcon from "../icons/BackIcon";
import CloseIcon from "../icons/CloseIcon";

import classes from "./ModalHeader.module.css";

const ModalHeader = ({ onClose, title, onBack, className }) => {
  return (
    <section className={`${classes.header} ${className}`}>
      {/* {onBack && (
        <button type="button" onClick={onBack}>
          <BackIcon className={classes.backButton} />
        </button>
      )} */}
      <h4>{title}</h4>
      {onClose && (
        <button type="button" onClick={onClose}>
          <CloseIcon className={classes.Button} />
        </button>
      )}
    </section>
  );
};

export default ModalHeader;

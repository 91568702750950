import { Button, Form, Input, Modal, message } from "antd";
import useWindowWidth from "helpers/useWindowWidth";
import React from "react";
import classes from "./ChangePassword.module.css";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";

function ChangePassword({ visible, onCancel, onSubmit }) {
  const width = useWindowWidth();
  const [form] = Form.useForm();
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const onFinish = (values) => {
    console.log("Received values:", values);
    form.validateFields().then(async (values) => {
      console.log("values, ", values);
      const sendingValues = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      const response = await fetch(`${API_BASE_URL}user/password/reset`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
        body: JSON.stringify(sendingValues),
      });
      const data = await response.json();

      if (data.success) {
        message.success("Password updated successfully.");
        onCancel();
      }

      if (!response.ok) {
        message.error(data.message);
        throw new Error(data.message);
      }
    });
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    },
  });

  return (
    <Modal
      className="review-modal"
      visible={visible}
      //   title="Create a Review"
      onCancel={onCancel}
      footer={null}
      width={width > 500 ? 418 : "90vw"}
      closeIcon={
        <img
          src="/img/icons/modal-close-icon.svg"
          style={{ width: "34px", height: "34px" }}
        />
      }
    >
      <div className={classes.modalWrapper}>
        <h1>Change Password</h1>
        <p>
          Enhance Your Account Security: Create a New Password to Safeguard Your
          Profile
        </p>
        <Form
          name="custom_form"
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          className={classes.profileForm}
        >
          <h4>Current Password</h4>
          <Form.Item
            name="oldPassword"
            rules={[
              { required: true, message: "Please input current password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <h4>New Password</h4>
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: "Please input new password!" },
              {
                validator: (_, value) => {
                  if (
                    !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}/.test(
                      value
                    )
                  ) {
                    return Promise.reject(
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <h4>Confirm Password</h4>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              validateConfirmPassword,
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default ChangePassword;

import { useState } from 'react';


// A hook thats takes an validation function as a paramater and returns some managing object for the input 
const useInput = (validate) => {
  // state for managing input value
  const [inputValue, setInputValue] = useState('');
  // state for managing if the user has clicked (touched) th input or not
  const [isTouched, setIsTouched] = useState(false);

  // always validate the input when the component re-render becuase of any of the state changes
  // call the passed validation function and pass to it the current input value state
  // validate function must returns a boolean
  const inputValueIsValid = validate(inputValue);
  // checks if the input has an error
  const inputHasAnError = !inputValueIsValid && isTouched;

  // change the value of the inputValue state whenever the user writes into the input
  // this function is passed as a onChange event handler
  const onChangeInputValueHandler = (event) => {
    setInputValue(event.target.value);
  };

  // change the isTouches state to true
  // this function is passed as a onBlur event handler
  const onBlurInputHanlder = () => {
    setIsTouched(true);
  };

  // reset the states value to default
  // this function is passed as a onReset event handler
  const onResetInputHandler = () => {
    setInputValue('');
    setIsTouched(false);
  };

  return {
    inputValue,
    inputValueIsValid,
    inputHasAnError,
    onChangeInputValueHandler,
    onBlurInputHanlder,
    onResetInputHandler,
  };
};

export default useInput;

import { useDispatch } from "react-redux";
import { modalActions } from "store-redux/modal-slice";

import SignupForm from "components/forms/sign-up/SignupForm";
import Modal from "components/UI/modal/Modal";
import ModalHeader from "components/UI/modal/ModalHeader";
// import Button from 'components/UI/button/Button';
// import GoogleIcon from 'components/UI/icons/GoogleIcon';
// import FacebookIcon from 'components/UI/icons/FacebookIcon';
// import TwitterIcon from 'components/UI/icons/TwitterIcon';
import classes from "./Signup.module.css";

const Signup = ({
  onClose,
  // showSignupDetailsModal,
}) => {
  const dispatch = useDispatch();

  const signInClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <div className={classes.wrapper}>
        <ModalHeader onClose={onClose} title="" onBack={signInClickHandler} />
        <section className={classes.body}>
          <SignupForm
          // onSumbitForm={showSignupDetailsModal}
          />
          {/* <p className={classes.lineBreak}>Or</p> */}
          {/* <ul className={classes.socialBtns}>
            <li>
              <Button type="button" option="uncolored">
                <GoogleIcon />
                Sign Up with Google
              </Button>
            </li>
            <li>
              <Button type="button" option="uncolored">
                <FacebookIcon />
                Sign Up with Facebook
              </Button>
            </li>
            <li>
              <Button type="button" option="uncolored">
                <TwitterIcon />
                Sign Up with Twitter
              </Button>
            </li>
          </ul> */}
        </section>
        {/* <section className={classes.signupFooter}>
          <p>
            Do you already have an account?{" "}
            <span className={classes.signInLink} onClick={signInClickHandler}>
              Sign In
            </span>
          </p>
        </section> */}
      </div>
    </Modal>
  );
};

export default Signup;

import React from 'react';

const ShareIcon = ({ className, onClick }) => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <rect width="38" height="38" rx="19" fill="#FF6666" />
            <path d="M24 29C23.1667 29 22.4583 28.7083 21.875 28.125C21.2917 27.5417 21 26.8333 21 26C21 25.8833 21.0083 25.7623 21.025 25.637C21.0417 25.5117 21.0667 25.3993 21.1 25.3L14.05 21.2C13.7667 21.45 13.45 21.646 13.1 21.788C12.75 21.93 12.3833 22.0007 12 22C11.1667 22 10.4583 21.7083 9.875 21.125C9.29167 20.5417 9 19.8333 9 19C9 18.1667 9.29167 17.4583 9.875 16.875C10.4583 16.2917 11.1667 16 12 16C12.3833 16 12.75 16.071 13.1 16.213C13.45 16.355 13.7667 16.5507 14.05 16.8L21.1 12.7C21.0667 12.6 21.0417 12.4877 21.025 12.363C21.0083 12.2383 21 12.1173 21 12C21 11.1667 21.2917 10.4583 21.875 9.875C22.4583 9.29167 23.1667 9 24 9C24.8333 9 25.5417 9.29167 26.125 9.875C26.7083 10.4583 27 11.1667 27 12C27 12.8333 26.7083 13.5417 26.125 14.125C25.5417 14.7083 24.8333 15 24 15C23.6167 15 23.25 14.9293 22.9 14.788C22.55 14.6467 22.2333 14.4507 21.95 14.2L14.9 18.3C14.9333 18.4 14.9583 18.5127 14.975 18.638C14.9917 18.7633 15 18.884 15 19C15 19.1167 14.9917 19.2377 14.975 19.363C14.9583 19.4883 14.9333 19.6007 14.9 19.7L21.95 23.8C22.2333 23.55 22.55 23.3543 22.9 23.213C23.25 23.0717 23.6167 23.0007 24 23C24.8333 23 25.5417 23.2917 26.125 23.875C26.7083 24.4583 27 25.1667 27 26C27 26.8333 26.7083 27.5417 26.125 28.125C25.5417 28.7083 24.8333 29 24 29Z" fill="white" />
        </svg>

    );
};

export default ShareIcon;
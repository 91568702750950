import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";

import { NavbarLinks, NavbarSideLinksPrivate } from "helpers/constants";

import NavbarItem from "./navbar-item/NavbarItem";

import classes from "./NavbarList.module.css";
import { Badge, Select } from "antd";
import { useEffect, useState } from "react";
import AccountDropdown from "../my-account/AccountDropdown";
import useWindowWidth from "helpers/useWindowWidth";
import { APP_PREFIX_PATH, GOOGLE_MAP_API_KEY } from "configs/AppConfig";
import Autocomplete from "react-google-autocomplete";
import { filterActions } from "store-redux/filter-slice";

const { Option } = Select;

const ratingData = [
  { value: 1, label: "4 Star & above" },
  { value: 2, label: "3 Star & above" },
  { value: 3, label: "2 Star & above" },
  { value: 4, label: "1 Star & above" },
];

// const eventData = [
//   { value: 1, label: "Wedding" },
//   { value: 2, label: "Birth days" },
//   { value: 3, label: "Party" },
//   { value: 4, label: "Anniversary" },
//   { value: 5, label: "Baptism" },
//   { value: 6, label: "Small Party" },
//   { value: 7, label: "Birth dayss" },
//   { value: 8, label: "Partys" },
//   { value: 9, label: "Anniversarys" },
//   { value: 10, label: "Baptisms" },
//   { value: 11, label: "Small Partys" },
// ];

const NavbarList = ({
  navigationList = null,
  className,
  onClose,
  isSide,
  isSearchOpen,
  proximityValues,
  filterDataChange,
  categoryData,
  filterData,
  eventData,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("isLoggedIn", isLoggedIn);
  const [myAccountModalOpen, setmyAccountModalOpen] = useState(false);
  const filters = useSelector((state) => state.filter);
  console.log("filter data in navbar", filterData);
  const cart = useSelector((state) => state.cart);
  const wishlist = useSelector((state) => state.wishlist);
  const [selectedAddress, setSelectedAddress] = useState();
  const [extractedValues, setExtractedValues] = useState({});
  console.log("selectedAddress", selectedAddress);

  const width = useWindowWidth();
  const location = useLocation();

  const isSearchpage = window.location.pathname.includes("/search");
  console.log("is search page", isSearchpage);

  useEffect(() => {
    if (filters) {
      setSelectedAddress(filters?.address);
    }
  }, [filters]);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const setMyAccountModalHandler = () => {
    setmyAccountModalOpen((value) => !value);
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    navigate("/");
  };

  let navList = null;
  if (navigationList) {
    navList = navigationList.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else if (!isSide || !isLoggedIn) {
    navList = NavbarLinks.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else {
    navList = NavbarSideLinksPrivate.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  }

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/img/icons/dropdown-arrow.svg"
      alt="arrow"
      style={{
        width: "24px",
        height: "24px",
        marginTop: "3px",
        marginRight: "-6px",
      }}
    /> // Customize the arrow color as needed
  );

  const locationFinder = (place) => {
    console.log("selected place", place);
    if (place.geometry && place.geometry.location) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const formattedAddress = place.formatted_address;
      const placeName = place.address_components[0]?.long_name;
      console.log("Latitude:", latitude);
      console.log("Longitude:", longitude);
      // setLatitude(latitude);
      // setLongitude(longitude);
      dispatch(
        filterActions.togglelocation({
          latitude: latitude,
          longitude: longitude,
          address: formattedAddress,
          place: placeName,
        })
      );

      const params = new URLSearchParams(window.location.search);
      params.set("latitude", latitude);
      params.set("longitude", longitude);
      // form.setFieldValue("address", formattedAddress);
      // You can set the latitude and longitude in your state or dispatch an action here
    }
  };

  //extract href values

  useEffect(() => {
    // Extract values using URLSearchParams
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const values = {};

    params.forEach((value, key) => {
      if (key == "latitude" || key == "longitude" || key == "range") {
        values[key] = Number(value);
      } else {
        values[key] = value;
      }
    });

    // Update state with extracted values
    setExtractedValues(values);
  }, [location.search]);

  console.log("extracted values in navbar", extractedValues);

  return (
    <ul className={`${classes.navbarList} ${className}`}>
      {!isSearchOpen && <div className={classes.pages}>{navList}</div>}
      {!isSearchOpen && (
        <div className={classes.links}>
          <div className={classes.iconLinks}>
            <img
              src="/img/icons/location-black.svg"
              alt="wishlist"
              className={classes.navIcon}
            />
            <Autocomplete
              className="custom-autocomplete"
              defaultValue={selectedAddress}
              key={selectedAddress} // This forces the component to re-render
              style={{
                width: "160px",
                borderRadius: "10px",
                height: "24px",
                padding: "8.5px 11px",
                border: "1px solid #fff",
                fontSize: "16px",
                color: "#0d0a19",
              }}
              // inputStyle={{
              //   width: "120px",
              // }}
              apiKey={GOOGLE_MAP_API_KEY}
              onPlaceSelected={locationFinder}
            />
            {/* <Select
              suffixIcon={customArrow}
              placeholder={<span style={placeholderStyle}>Location</span>}
              style={{ height: "20px" }}
              rootClassName="navbar-select"
              defaultValue="Kochi"
            >
              <Option value="Kochi">Kochi</Option>
              <Option value="Thrissur">Thrissur</Option>
              <Option value="Kannur">Kannur</Option>
            </Select> */}
          </div>
          <NavbarItem
            onClick={!isLoggedIn && setSigninModalHandler.bind()}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/wishlist"}
          >
            {wishlist?.totalItems > 0 && (
              <Badge
                count={wishlist?.totalItems}
                overflowCount={9}
                style={{
                  backgroundColor: "#FF6666",
                }}
              >
                <img
                  src="/img/icons/wishlist.svg"
                  alt="wishlist"
                  className={classes.navIcon}
                />
              </Badge>
            )}
            {(!wishlist?.totalItems || wishlist?.totalItems <= 0) && (
              <img
                src="/img/icons/wishlist.svg"
                alt="wishlist"
                className={classes.navIcon}
              />
            )}
            {/* <img
              src="/img/icons/wishlist.svg"
              alt="wishlist"
              className={classes.navIcon}
            /> */}
            Wishlist
          </NavbarItem>
          {!isLoggedIn && (
            <NavbarItem onClick={setSigninModalHandler}>
              <img
                src="/img/icons/login.svg"
                alt="login"
                className={classes.navIcon}
              />
              Login
            </NavbarItem>
          )}
          {isLoggedIn && (
            <div>
              <NavbarItem onClick={setMyAccountModalHandler}>
                <img
                  src="/img/icons/login.svg"
                  alt="login"
                  className={classes.navIcon}
                />
                My Account
                <img
                  src="/img/icons/dropdown-arrow.svg"
                  alt="login"
                  className={classes.navIcon}
                  style={{ marginTop: "-1px" }}
                />
              </NavbarItem>
              {myAccountModalOpen && (
                <AccountDropdown
                  myAccountModalOpen={myAccountModalOpen}
                  setmyAccountModalOpen={setmyAccountModalOpen}
                />
              )}
            </div>
          )}
          <NavbarItem
            onClick={!isLoggedIn && setSigninModalHandler.bind()}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/cart"}
          >
            {cart?.cartTotal > 0 && (
              <Badge
                count={cart?.cartTotal}
                // overflowCount={9}
                style={{
                  backgroundColor: "#FF6666",
                }}
              >
                <img
                  src="/img/icons/cart-black.svg"
                  alt="cart"
                  className={classes.navIcon}
                />
              </Badge>
            )}
            {(!cart?.cartTotal || cart?.cartTotal <= 0) && (
              <img
                src="/img/icons/cart-black.svg"
                alt="cart"
                className={classes.navIcon}
              />
            )}
            Cart
          </NavbarItem>
          {/* {!isLoggedIn && isSide && (
          <NavbarItem onClick={setSigninModalHandler}>Log in</NavbarItem>
        )}
        {isLoggedIn && isSide && (
          <NavbarItem pageLink="/" onClick={onClickSignoutHandler}>
            Log out
          </NavbarItem>
        )} */}
        </div>
      )}
      {isSearchOpen && (
        <div className={classes.searchOptions}>
          <Select
            showSearch
            value={isSearchpage ? extractedValues?.range : undefined}
            onChange={(value) => filterDataChange("range", value)}
            suffixIcon={customArrow}
            placeholder={<span style={placeholderStyle}>Proximity</span>}
            style={{
              width: "12.5vw",
              height: "40px",
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {proximityValues.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <Select
            showSearch
            value={isSearchpage ? extractedValues?.categoryId : undefined}
            onChange={(value) => filterDataChange("categoryId", value)}
            suffixIcon={customArrow}
            placeholder={<span style={placeholderStyle}>Category</span>}
            style={{
              width: "12.5vw",
              height: "40px",
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoryData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <Select
            showSearch
            value={isSearchpage ? extractedValues?.eventType : undefined}
            onChange={(value) => filterDataChange("eventType", value)}
            suffixIcon={customArrow}
            placeholder={<span style={placeholderStyle}>Event Type</span>}
            style={{
              width: "12.5vw",
              height: "40px",
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {eventData.map((option) => (
              <Option key={option.name} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
          <Select
            onChange={(value) => filterDataChange("ratingId", value)}
            value={isSearchpage ? extractedValues?.ratingId : undefined}
            suffixIcon={customArrow}
            placeholder={<span style={placeholderStyle}>Rating</span>}
            style={{
              width: "12.5vw",
              height: "40px",
            }}
          >
            {ratingData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </ul>
  );
};

export default NavbarList;

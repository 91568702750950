import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalIsVisible: false,
  modalType: null
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal(state, action) {
      if (action.payload?.show) {
        return (state.modalIsVisible = action.payload.show);
      }
      // toogle the visability of the Modal change true to false - false to true
      state.modalIsVisible = !state.modalIsVisible;
    },
    changeModalComponent(state, action) {
      // Change the modal component to show
      state.modalType = action.payload.modalType;
      state.modalIsVisible = true;
    }
  }
});

export const modalActions = modalSlice.actions;
export default modalSlice.reducer;

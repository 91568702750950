import React, { useState } from "react";
import classes from "./WishListPackageCards.module.css";
import packageData from "components/page-content/package-search/PackageData";
import PackageCard from "components/page-content/package-search/Packagecard";
import WeddingPagination from "components/WeddingPagination/WeddingPagination";

function WishListPackageCards({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  isVisible,
  setIsVisible,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsCountPerPage = 20;
  const totalItemsCount = 400;

  console.log("current page", currentPage);

  return (
    <>
      <div className={classes.cardsOuter}>
        <div className={classes.cardFlex}>
          {packageData.map((card) => (
            <div className={classes.eventCardWrapper}>
              <PackageCard
                image={card.img}
                title={card.title}
                review={card.review}
                city={card.city}
                state={card.state}
                price={card.price}
                strikePrice={card.strikePrice}
                details={card.details}
              />
            </div>
          ))}
        </div>
        {/* <CustomPagination
     currentPage={currentPage}
     totalPage={totalPage}
     setCurrentPage = {setCurrentPage}
  
   /> */}
        <WeddingPagination
          setCurrentPage={setCurrentPage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
        />
      </div>
    </>
  );
}

export default WishListPackageCards;

import classes from './ErrorLabel.module.css';

const options = {
  yellow: classes.yellowLabel,
  green: classes.greenLabel
};

const ErrorLabel = ({ children, option = '', className = '' }) => {
  return (
    <span className={`${classes.invalidInput} ${options[option]} ${className}`}>
      {children}
    </span>
  );
};

export default ErrorLabel;

const packageData = [
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena Montreal CarnivalThe Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: ["Stella Makeups", "MoonDream art", "Luminous Decoration"],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
    details: [
      "Stella Makeups",
      "MoonDream art",
      "Luminous Decoration",
      "MoonDream art",
    ],
  },
];

export default packageData;

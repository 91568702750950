import React, { useState, useRef, useEffect } from "react";
import classes from "./AccountDropdown.module.css";
import Backdrop from "components/UI/modal/Backdrop";
import Button from "components/UI/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "store-redux/user-slice";
import { Link, useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { modalActions } from "store-redux/modal-slice";

const AccountDropdown = ({ myAccountModalOpen, setmyAccountModalOpen }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const logoutHandler = () => {
    dispatch(userActions.logout());
    setmyAccountModalOpen(false);
    console.log("after logout isloggedin", isLoggedIn);
    navigate(`/`, { replace: true });
  };

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  return (
    <>
      <div className={classes.dropdown}>
        <div ref={dropdownRef} className={classes.list}>
          {/* Content for your account options */}
          <Link
            onClick={() => setmyAccountModalOpen(false)}
            to={APP_PREFIX_PATH + "/bookings"}
          >
            <p>My Bookings</p>
          </Link>
          <hr className={classes.line} />
          <Link
            onClick={() => setmyAccountModalOpen(false)}
            to={APP_PREFIX_PATH + "/profile"}
          >
            <p>Profile Settings</p>
          </Link>

          <Button
            option="uncolored"
            className={classes.logout}
            onClick={logoutHandler.bind()}
          >
            Logout
          </Button>
        </div>
      </div>
      <Backdrop onClose={() => setmyAccountModalOpen(false)} transparent />
    </>
  );
};

export default AccountDropdown;

import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import classes from "./Calendar.module.css";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const CalendarUI = ({
  setDayValue,
  details,
  setSelectedDate,
  mode,
  intervals,
  setIntervals,
}) => {
  const [value, onChange] = useState();
  console.log("value of date", value);

  useEffect(() => {
    if (value) {
      setDayValue(days[value.getDay()]);

      const year = value.getFullYear();
      const month = String(value.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const day = String(value.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      setSelectedDate(formattedDate);
    }
  }, [value]);

  // Function to render content in date tile
  const tileContent = ({ date, view }) => {
    const priceObject = {};

    // const resultArray = details.customPriceDates.filter(
    //   (item) => !details.removedDates.includes(item.date)
    // );

    // const resultArray2 = resultArray?.filter(itemA =>
    //     details?.availableDates.some(itemB => itemB.date === itemA.date)
    // );

    const resArray = details?.availableDates?.filter(
      (item) => !details.removedDates.includes(item.date)
    );

    const notInBooked = resArray.filter(
      (item) => !details.bookedDates.some((data)=>data.date == item.date)
    );

    notInBooked?.forEach((item) => {
      priceObject[item.date] = item.intervals[0].price;
    });

    console.log('price object before', priceObject)

    // details?.customPrice?.forEach((item) => {
    //   if (priceObject[item.date] !== undefined) {
    //     console.log('this operation happening')
    //     priceObject[item.date] = item.price; // Convert price to string if necessary
    //   } else {
    //     // console.log(`Date ${item.date} not found in prices object.`);
    //   }
    // });

    // Object.keys(priceObject).forEach((item)=>{
    //   const dateToCheck = new Date(item);
    //   const customPrice = {...details?.customPrice[0]}
    //   const fromDate = new Date(customPrice?.fromDate)
    //   const toDate = new Date(customPrice?.toDate)
    //   if (dateToCheck >= fromDate && dateToCheck <= toDate) {
    //     priceObject[item] = customPrice.price;
    //   }
    // })

    Object.keys(priceObject).forEach((item) => {
      const dateToCheck = new Date(item);
      
      details?.customPrice.forEach(customPrice => {
        const fromDate = new Date(customPrice?.fromDate);
        const toDate = new Date(customPrice?.toDate);
    
        if (dateToCheck >= fromDate && dateToCheck <= toDate) {
          priceObject[item] = customPrice.price;
        }
      });
    });

    // console.log("price object is", priceObject);
    // Example: Add price to specific dates
    // const prices = {
    //   "2024-03-17": "1030000",
    //   "2024-03-20": "1550000",
    //   "2024-03-21": "1500000",
    // };

    // const price = prices[date.toISOString().slice(0, 10)];  //using this creates error of timezone
    const price =
      priceObject[
        new Date(date.getTime() - date.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 10)
      ]; //accomodating time zone differnece
    // console.log("date ---", date.toISOString().slice(0, 10));
    // console.log("prices ---", price);

    if (price && view === "month") {
      //price converted in global stds
      // if (price >= 1000000) {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{(Number(price) / 1000000).toFixed(1).replace(/\.?0*$/, '') + 'M'}</p>;
      // } else if (price >= 1000) {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{(Number(price) / 1000).toFixed(1).replace(/\.?0*$/, '') + 'K'}</p>;
      // } else {
      //     return <p style={{ margin: "0", color: "#333333" }}>₹{Number(price).toString()}</p>;
      // }

      //price converted in Indian stds
      if (price >= 10000000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹
            {(Number(price) / 10000000).toFixed(1).replace(/\.?0*$/, "") + "Cr"}
          </p>
        );
        // return (value / 10000000).toFixed(1) + ' Cr';
      } else if (price >= 100000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹{(Number(price) / 100000).toFixed(1).replace(/\.?0*$/, "") + "L"}
          </p>
        );
        // return (value / 100000).toFixed(1) + ' L';
      } else if (price >= 1000) {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹{(Number(price) / 1000).toFixed(1).replace(/\.?0*$/, "") + "T"}
            {/* ₹{Number(price)?.toLocaleString("en-IN")} */}
          </p>
        );
        // return (value / 1000).toFixed(1) + ' T';
      } else {
        return (
          <p style={{ margin: "0", color: "#333333" }}>
            ₹{Number(price).toString()}
          </p>
        );
        // return value.toString();
      }
      // return <p>{price}</p>;
    }
    return <h6 style={{ margin: "0", color: "transparent" }}>--</h6>;
  };

  // const tileDisabled = ({ date }) => {
  //     // Define an array of specific dates to disable in 'YYYY-MM-DD' format
  //     const disabledDates = [
  //         '2024-03-15',
  //         '2024-03-20',
  //     ];

  //     // Convert the date to 'YYYY-MM-DD' format for comparison
  //     // const formattedDate = date.toISOString().slice(0, 10); //using this creates error of timezone
  //     const formattedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10); //accomodating time zone differnece

  //     return disabledDates.includes(formattedDate);
  // };

  const yesterday = new Date();

  const tileDisabled = ({ date }) => {
    // Define an array of specific dates to disable in 'YYYY-MM-DD' format

    const disabled = details?.availableDates?.map((item) => item.date);
    const disabledFinal = disabled?.filter(
      (item) => !details?.removedDates.includes(item)
    );

    const notInBooked = disabledFinal.filter(
      (item) => !details.bookedDates.some((data)=>data.date == item)
    );
  

    const dateToShow = notInBooked.filter(date => {
      const dateObj = new Date(date);
      return dateObj >= yesterday;
  });

    // console.log("disabled date values", disabledFinal);

    const disabledDates = ["2024-03-15", "2024-03-20"];

    // Convert the date to 'YYYY-MM-DD' format for comparison
    // const formattedDate = date.toISOString().slice(0, 10); //using this creates error of timezone
    const formattedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10); //accomodating time zone differnece

    return !dateToShow?.includes(formattedDate);
  };

  return (
    <div className={classes.calendarContainer}>
      <Calendar
        onChange={onChange}
        // value={value}
        tileContent={tileContent}
        tileDisabled={tileDisabled}
      />
      {/* <p>Selected Date: {value.toLocaleDateString()}</p>
            <p>Day of the Month: {days[value.getDay()]}</p> */}
    </div>
  );
};

export default CalendarUI;

import React, { useState } from "react";
import classes from "./ItemInfo.module.css";
import MapIconGray from "components/UI/icons/MapIconGray";
import WishlistIcon from "components/UI/icons/WishlistIcon";
import ShareIcon from "components/UI/icons/ShareIcon";
import StarRatings from "react-star-ratings";
import { Modal, message } from "antd";
import CloseIcon from "components/UI/icons/CloseIcon";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "configs/AppConfig";
import { wishlistActions } from "store-redux/wishlist-slice";
import WishlistIconWhite from "components/UI/icons/WishlistIconWhite";
import { LoadingOutlined } from "@ant-design/icons";
import { modalActions } from "store-redux/modal-slice";

const ItemInfo = ({ details }) => {
  const [showShareModal, setShowShareModal] = useState(false);
  console.log("share modal", showShareModal);
  const currentUrl = window.location.href;
  console.log("currentUrl", currentUrl);

  const { wishlist } = useSelector((state) => state.wishlist);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false);

  const removeItemFromWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/${id}/delete`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    console.log("wishlist removal -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.error("Item successfully removed from Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const addItemToWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        listingId: id,
      }),
    });
    const data = await response.json();
    console.log("wishlist adding -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.success("Item added to Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const wishlistHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
      return;
    }
    if (wishlist?.some((item) => item.listingId === details?._id)) {
      //to remove item from wishlist if its already in wishlist
      const wishlistItemId = wishlist.find(
        (item) => item.listingId === details?._id
      );
      console.log("wishlist item to be removed", wishlistItemId);
      removeItemFromWishlist(wishlistItemId._id);
    } else {
      //to add item to wishlist if its not in wishlist
      addItemToWishlist(details?._id);
    }
  };

  return (
    <div className={classes.infoContainer}>
      <div className={classes.leftSection}>
        <h1 className={classes.name}>{details?.name}</h1>
        <div className={classes.cityWrapper}>
          <MapIconGray className={classes.cityIcon} />
          {/* Kochi , Kerala */}
          {details?.location?.locationName}
        </div>
        <h2 className={classes.address}>
          {details?.address}
          {/* Dream Arena , Marcus Road , Kanadikkand , Kochi , Ernakulam , PO
          685059 */}
        </h2>
      </div>
      <div className={classes.rightSection}>
        {/* <div className={classes.ratingWrapper}>
          <StarRatings
            rating={3.403}
            starDimension="18px"
            starSpacing="2.25px"
            starRatedColor="#FFE234"
          />
          <h1>4.7 Rating</h1>
        </div> */}
        <div className={classes.iconWrapper}>
          {apiLoading && (
            <LoadingOutlined
              style={{ fontSize: "28px" }}
              className={classes.loadingIcon}
            />
          )}
          {wishlist?.some((item) => item.listingId === details?._id) &&
            !apiLoading && (
              <WishlistIconWhite
                onClick={(e) => wishlistHandler(e)}
                className={classes.wishlistIcon}
              />
            )}
          {!wishlist?.some((item) => item.listingId === details?._id) &&
            !apiLoading && (
              <WishlistIcon
                onClick={(e) => wishlistHandler(e)}
                className={classes.wishlistIcon}
              />
            )}
          <ShareIcon
            className={classes.shareIcon}
            onClick={() => setShowShareModal(true)}
          />
        </div>
      </div>
      <Modal
        title="Share link via Social Media"
        // centered
        open={showShareModal}
        onCancel={() => setShowShareModal(false)}
        className={classes.videoModal}
        footer={null}
        closeIcon={<CloseIcon />}
        // width={"55vw"}
        // bodyStyle={{ height: "50vh", overflow: "auto" }}
        // height={"800px"}
        // style={{ height: "400px" }}
        // style={{ overflowY: "auto" }}
      >
        <div className={classes.shareButtonsContainer}>
          <FacebookShareButton url={currentUrl}>
            <FacebookIcon size={38} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={currentUrl}>
            <WhatsappIcon size={38} round={true} />
          </WhatsappShareButton>
          <TwitterShareButton url={currentUrl}>
            <TwitterIcon size={38} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={currentUrl}>
            <LinkedinIcon size={38} round={true} />
          </LinkedinShareButton>
          <EmailShareButton url={currentUrl}>
            <EmailIcon size={38} round={true} />
          </EmailShareButton>
          <TelegramShareButton url={currentUrl}>
            <TelegramIcon size={38} round={true} />
          </TelegramShareButton>
        </div>
      </Modal>
    </div>
  );
};

export default ItemInfo;

import React, { useRef } from "react";
import classes from "./VenueCarousel.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftArrowBlack from "components/UI/icons/LeftArrowBlack";
import RightArrowBlack from "components/UI/icons/RightArrowBlack";

const VenueCarousel = ({ images }) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    // slidesToShow: 2.6,
    slidesToScroll: 1,
    autoplay: false,
    margin: 0,
    centerMode: false,
    // variableWidth: true,
    arrows: false,
  };

  const handleNextArrowClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePreviousArrowClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      console.log("value is", sliderRef.current);
    }
  };

  return (
    <div className={classes.venueSlider}>
      <LeftArrowBlack
        className={classes.leftArrow}
        onClick={handlePreviousArrowClick}
      />
      <RightArrowBlack
        className={classes.rightArrow}
        onClick={handleNextArrowClick}
      />
      <Slider {...settings} ref={sliderRef} className="slider-style">
        {images?.map((item, index) => (
          <img
            src={item}
            alt={index}
            key={item + index}
            className={classes.image}
          />
        ))}
      </Slider>
    </div>
  );
};

export default VenueCarousel;

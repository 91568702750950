import React from "react";
import classes from "./Timings.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import moment from "moment";

const Timings = ({ days, timings, details }) => {
  const width = useWindowWidth();
  return (
    <div className={classes.timingsContainer}>
      <div className={classes.sectionWrapper}>
        <h1 className={classes.title}>Available Days</h1>
        <div className={classes.tagWrapper}>
          {days?.map((item) => (
            <div className={classes.tag}>{item}</div>
          ))}
        </div>
      </div>
      {width > 1200 && <div className={classes.verticalLine}></div>}

      <div className={classes.sectionWrapper}>
        <h1 className={classes.title}>Timings</h1>
        {details?.slotType !== "Slot" && (
          <div className={classes.tagWrapper}>
            {timings?.map((item) => (
              <div className={classes.tag}>
                {moment(item?.start, "HH:mm")?.format("h:mm A")} -{" "}
                {moment(item?.end, "HH:mm")?.format("h:mm A")}
              </div>
            ))}
          </div>
        )}
        {details?.slotType === "Slot" && (
          <div className={classes.tagWrapper}>
            <div className={classes.tag}>Not Available</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Timings;

import React from 'react';

const WishlistIcon = ({ className, onClick }) => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <rect width="38" height="38" rx="19" fill="#FF6666" />
            <path d="M18.1386 26.0497L18.1379 26.0491C16.0036 24.1314 14.2726 22.5731 13.0693 21.1144C11.8713 19.6622 11.25 18.3709 11.25 16.9959C11.25 14.7511 13.0177 13 15.2875 13C16.5736 13 17.8149 13.5958 18.6221 14.5276L19 14.9639L19.3779 14.5276C20.1851 13.5958 21.4264 13 22.7125 13C24.9823 13 26.75 14.7511 26.75 16.9959C26.75 18.3709 26.1287 19.6622 24.9307 21.1144C23.7274 22.5731 21.9964 24.1314 19.8621 26.0491L19.8614 26.0497L19 26.8266L18.1386 26.0497Z" stroke="white" />
        </svg>

    );
};

export default WishlistIcon;


const PasswordIcon = ({ className }) => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.32031 6.01172V4.5C9.32031 3.86719 9.08594 3.32813 8.61719 2.88281C8.17188 2.41406 7.63281 2.17969 7 2.17969C6.36719 2.17969 5.81641 2.41406 5.34766 2.88281C4.90234 3.32813 4.67969 3.86719 4.67969 4.5V6.01172H9.32031ZM5.94531 12.3047C6.25 12.6094 6.60156 12.7617 7 12.7617C7.39844 12.7617 7.75 12.6094 8.05469 12.3047C8.35938 12 8.51172 11.6484 8.51172 11.25C8.51172 10.8516 8.35938 10.5 8.05469 10.1953C7.75 9.89062 7.39844 9.73828 7 9.73828C6.60156 9.73828 6.25 9.89062 5.94531 10.1953C5.64062 10.5 5.48828 10.8516 5.48828 11.25C5.48828 11.6484 5.64062 12 5.94531 12.3047ZM11.5 6.01172C11.8984 6.01172 12.25 6.16406 12.5547 6.46875C12.8594 6.75 13.0117 7.08984 13.0117 7.48828V15.0117C13.0117 15.4102 12.8594 15.7617 12.5547 16.0664C12.25 16.3477 11.8984 16.4883 11.5 16.4883H2.5C2.10156 16.4883 1.75 16.3477 1.44531 16.0664C1.14062 15.7617 0.988281 15.4102 0.988281 15.0117V7.48828C0.988281 7.08984 1.14062 6.75 1.44531 6.46875C1.75 6.16406 2.10156 6.01172 2.5 6.01172H3.23828V4.5C3.23828 3.46875 3.60156 2.58984 4.32812 1.86328C5.07812 1.11328 5.96875 0.738281 7 0.738281C8.03125 0.738281 8.91016 1.11328 9.63672 1.86328C10.3867 2.58984 10.7617 3.46875 10.7617 4.5V6.01172H11.5Z"
        fill="#000000"
      />
    </svg>
  );
};

export default PasswordIcon;



const tabScreen = (size) => {
    switch (size) {
        case 36:
            return 24;

        case 24:
            return 16;

        case 18:
            return 16;

        case 16:
            return 14;

        case 14:
            return 12;

        case 12:
            return 10;

        case 10:
            return 8;

        default:
            return size;
    }
}

const mobileScreen = (size) => {
    switch (size) {
        case 36:
            return 18;

        case 24:
            return 14;

        case 18:
            return 14;

        case 16:
            return 12;

        case 14:
            return 10;

        case 12:
            return 8;

        default:
            return size;
    }
}

const AdjustFontSize = (html, width) => {

    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Get all elements with inline styles
    const elements = Array.from(doc.querySelectorAll('*[style]'));

    elements.forEach(element => {
        const style = element.getAttribute('style');
        const fontSizeMatch = style.match(/font-size:\s*(\d+px)/);

        if (fontSizeMatch) {
            const currentSize = parseInt(fontSizeMatch[1], 10);
            console.log("current size", currentSize)
            let newSize = currentSize;
            //replacing fontsize given in html tags according to screen size
            if (width < 757) {
                newSize = mobileScreen(currentSize);
            } else
                if (width < 1024) {
                    newSize = tabScreen(currentSize);
                }
            console.log("new size", newSize)
            element.setAttribute('style', style.replace(/font-size:\s*\d+px/, `font-size: ${newSize}px`));
        }
    });

    return doc.body.innerHTML;
};

export default AdjustFontSize;

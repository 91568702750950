import React, { useEffect, useState } from "react";
import classes from "./ProfileSettings.module.css";
import { Form, Input, Button, Row, Col, message } from "antd";
import ChangePassword from "./ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { ProfileDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { modalActions } from "store-redux/modal-slice";

function ProfileSettings() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [modalVisible, setModalVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  console.log("mobile number", mobileNumber);
  const [name, setName] = useState();
  console.log("name", name);
  const [email, setEmail] = useState();
  console.log("email", email);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    } else if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  }, [isLoggedIn])

  const {
    isLoading,
    data: profileData,
    refetch: fetchProfile,
  } = useQuery(
    [ProfileDataKey, user],
    async () => {
      const response = await fetch(`${API_BASE_URL}user/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        message.error(data.message);
        throw new Error(data.message);
      }

      return data.data;
    }
    // {
    //   retry: 0, // Will retry failed requests 10 times before displaying an error
    //   retryDelay: 1000,
    //   initialData: [],
    // }
  );
  console.log("profile data", profileData);

  useEffect(() => {
    if (profileData) {
      setName(profileData.name);
      setEmail(profileData.email);
      setMobileNumber(profileData.phoneNo);
      form.setFieldsValue({
        name: profileData.name,
        email: profileData.email,
        phoneNo: profileData.phoneNo,
      })
    }
  }, [profileData])


  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleModalChange = () => {
    setModalVisible(true);
  }

  const onFinish = (event) => {
    // event.preventDefault();
    // console.log("Received values:", event);
    form
      .validateFields()
      .then(async (values) => {
        console.log("values, ", values);
        values.type = "CUSTOMER";

        const response = await fetch(`${API_BASE_URL}user/update/profile`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
          body: JSON.stringify(values),
        });
        const data = await response.json();

        if (data.success) {
          fetchProfile();
          message.success("Profile successfully updated.");
        }

        if (!response.ok) {
          message.error(data.message);
          throw new Error(data.message);
        }
      })
  };

  return (
    <>
      <div className={classes.profileWrapper}>
        <h1>Profile Settings</h1>
        <Form
          name="custom_form"
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          className={classes.profileForm}
        >
          <h4>Name</h4>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input
              className={classes.nameField}
            // value={name}
            // onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <h4>Email</h4>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input
              className={classes.emailField}
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <h4>Phone Number</h4>
          <Form.Item
            name="phoneNo"
            rules={[
              { required: true, message: "Please input your phone number!" },
              {
                pattern: /^(\+91)?\d{6}(\d{4})$/, // assuming 10-digit phone numbers
                message: "Please enter a valid 10-digit phone number!",
              },
            ]}
          >
            <PhoneInput
              className="phone-ant-input"
              country="IN"
              defaultCountry="IN"
            // value={mobileNumber}
            // onChange={(e) => {
            //   console.log("phone input value", e);
            //   setMobileNumber(e);
            // }}
            // onBlur={checkPhoneNumber}
            // placeholder="Type Your Phone Number"
            />
            {/* <Input type="number" className={classes.numberField} /> */}
          </Form.Item>
          <p onClick={handleModalChange} >Change Password</p>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ChangePassword visible={modalVisible} onCancel={handleCloseModal} />
    </>
  );
}

export default ProfileSettings;

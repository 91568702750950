
import React from 'react';

const RightLongArrow = ({ className, onClick }) => {
    return (
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <path d="M18.7852 9.59592L13.7227 14.6584C13.5642 14.8169 13.3492 14.906 13.1251 14.906C12.9009 14.906 12.6859 14.8169 12.5274 14.6584C12.3689 14.4999 12.2798 14.2849 12.2798 14.0608C12.2798 13.8366 12.3689 13.6216 12.5274 13.4631L16.1492 9.84271H2.84375C2.61997 9.84271 2.40536 9.75382 2.24713 9.59558C2.08889 9.43735 2 9.22274 2 8.99896C2 8.77519 2.08889 8.56058 2.24713 8.40234C2.40536 8.24411 2.61997 8.15521 2.84375 8.15521H16.1492L12.5288 4.53271C12.3703 4.37421 12.2812 4.15922 12.2812 3.93506C12.2812 3.71089 12.3703 3.49591 12.5288 3.3374C12.6873 3.17889 12.9023 3.08984 13.1265 3.08984C13.3506 3.08984 13.5656 3.17889 13.7241 3.3374L18.7866 8.3999C18.8653 8.47839 18.9277 8.57166 18.9702 8.67433C19.0127 8.777 19.0346 8.88707 19.0344 8.9982C19.0343 9.10933 19.0122 9.21935 18.9695 9.32192C18.9267 9.42449 18.8641 9.51761 18.7852 9.59592Z" fill="#FF6666" />
        </svg>
    );
};

export default RightLongArrow;
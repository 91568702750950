import { configureStore } from '@reduxjs/toolkit';

import modalReducer from './modal-slice';
import userReducer from './user-slice';
import filterReducer from './filter-slice';
import cartReducer from './cart-slice';
import wishlistReducer from './wishlist-slice';

const store = configureStore({
  reducer: { modal: modalReducer, user: userReducer, filter: filterReducer, cart: cartReducer, wishlist: wishlistReducer }
});

export default store;

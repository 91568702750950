import { NavLink, Link } from 'react-router-dom';

import classes from './NavbarItem.module.css';

const NavbarItem = ({ children, pageLink, onClick, navItem }) => {
  return (
    <li className={classes.navbarItem}>
      {navItem && (
        <NavLink
          onClick={onClick}
          to={pageLink}
          end
          className={({ isActive }) => (isActive ? classes.active : undefined)}
        >
          {children}
        </NavLink>
      )}
      {!navItem && (
        <Link onClick={onClick} to={pageLink}>
          {children}
        </Link>
      )}
    </li>
  );
};

export default NavbarItem;

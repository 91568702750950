import React, { useEffect, useRef, useState } from "react";
import classes from "./SearchFilters.module.css";
import { Slider, InputNumber, Row, Col, Select, Button } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import { CaretDownOutlined } from "@ant-design/icons";
import { GOOGLE_MAP_API_KEY } from "configs/AppConfig";
import { filterActions } from "store-redux/filter-slice";

function SearchFilters({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  catArray,
  setCatArray,
  eventArray,
  setEventArray,
  priceValue,
  setPriceValue,
  ratingArray,
  setRatingArray,
  proximityData,
  setProximitydata,
  isVisible,
  setIsVisible,
  categoryData,
  extractedValues,
  setExtractedValues,
  appliedFilters,
  setAppliedFilters,
  filterValues,
  setFilterValues,
  eventData,
  vendorData,
}) {
  const { Option } = Select;

  const myDivRef = useRef(null);

  const filtersdata = ["Venue", "Wedding", "Within 5 Km"];
  const { latitude, longitude, place } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [locationSelected, setLocationSelected] = useState();
  const filters = useSelector((state) => state.filter);
  const [selectedAddress, setSelectedAddress] = useState();

  useEffect(() => {
    if (filters) {
      setSelectedAddress(filters?.address);
    }
  }, [filters]);

  const proximityValue = [
    { label: "Within 5 km", value: 5 },
    { label: "Within 10 km", value: 10 },
    { label: "Within 20 km", value: 20 },
    { label: "Within 50 km", value: 50 },
    { label: "Within 75 km", value: 75 },
  ];

  // const eventData = [
  //   { value: 1, label: "Wedding" },
  //   { value: 2, label: "Birth days" },
  //   { value: 3, label: "Party" },
  //   { value: 4, label: "Anniversary" },
  //   { value: 5, label: "Baptism" },
  //   { value: 6, label: "Small Party" },
  //   { value: 7, label: "Birth dayss" },
  //   { value: 8, label: "Partys" },
  //   { value: 9, label: "Anniversarys" },
  //   { value: 10, label: "Baptisms" },
  //   { value: 11, label: "Small Partys" },
  // ];
  const priceData = [
    "Under ₹400",
    "₹400 - ₹800",
    "₹800 to ₹1000",
    "₹1000 to ₹1200",
  ];
  const ratingData = [
    { value: 1, label: "4 Star & above" },
    { value: 2, label: "3 Star & above" },
    { value: 3, label: "2 Star & above" },
    { value: 4, label: "1 Star & above" },
  ];

  const [minPrice, setMinPrice] = useState(100000);
  const [maxPrice, setMaxPrice] = useState(500000);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [className, setClassName] = useState("filterOuter");
  const [flag, setFlag] = useState(0);

  // useEffect(()=>{
  //   setFilterValues(appliedFilters)
  // },[appliedFilters])

  console.log("category data is", categoryData);

  const navigate = useNavigate();

  const constructQueryString = (filterData) => {
    //to create search params from extracted values
    const queryParams = [];
    for (const key in filterData) {
      if (filterData[key] !== "" && filterData[key] !== undefined) {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(filterData[key])}`
        );
      }
    }
    return queryParams.join("&");
  };

  useEffect(() => {
    const hasNonEmptyValues = Object.values(extractedValues).some(
      (value) => value !== ""
    );

    // extractedValues.latitude = latitude;

    // extractedValues.longitude = longitude;

    console.log("has non empty value in navigate", extractedValues);
    if (flag == 1) {
      //initially when coming to this page from home page,first we have to wait until the extractedvalues take all values from href.then only start this process.otherwise it directly goes to /search , dont add search params in url.so we make a useeffect below this to make flag 1 when values are taken from url to extracted values
      console.log("will it work");
      const queryString = constructQueryString(extractedValues);
      const baseUrl = "http://localhost:3000/search"; // Replace 'your_base_url_here' with your actual base URL
      const finalUrl = queryString ? `/search?${queryString}` : "";
      // window.location.href = finalUrl;
      navigate(queryString ? `/search?${queryString}` : "/search");
    }
  }, [extractedValues]); //whenever extracted values change,make sure url changes

  useEffect(() => {
    const hasNonEmptyValues = Object.values(extractedValues).some(
      //initially check if extracted values has any values,we may have searched any values from home page
      (value) => value !== ""
    );
    if (hasNonEmptyValues) {
      setFlag(1);
    } else {
      //if we just clicked search button from home page, setflag(1) wont work, so do this to make flag 1
      setTimeout(() => {
        setFlag(1);
      }, 1000);
    }
  }, [extractedValues]);

  useEffect(() => {
    const hasNonEmptyValues = Object.values(extractedValues).some(
      (value) => value !== ""
    );

    console.log("has non empty value", hasNonEmptyValues);

    if (hasNonEmptyValues) {
      const arrayFromObject = Object.entries(extractedValues).map(
        // to fill appliedfilters
        ([key, value]) => ({
          name: key,
          value:
            key == "categoryId"
              ? categoryData.filter((item) => item.value == value)[0]?.label //this gives an array in object like {categoryid: categoryname}
              : key == "eventType"
              ? eventData.filter((item) => item.value == value)[0]?.label
              : key == "ratingId"
              ? ratingData.filter((item) => item.value == value)[0]?.label
              : key == "range"
              ? proximityValue.filter((item) => item.value == value)[0]?.label
              : key == "vendorId"
              ? vendorData?.filter((item) => item?.value == value)[0]?.label
              : // : key == "latitude"
                // ? value
                // : key == "longitude"
                // ? value
                "",
        })
      );

      console.log("array from object", arrayFromObject, vendorData);

      // const filteredArray = arrayFromObject.filter(
      //   (obj) =>
      //     obj.value !== undefined &&
      //     obj?.name !== "latitude" &&
      //     obj?.name !== "longitude"
      // );
      // setAppliedFilters([...filteredArray, { name: place, value: place }]);

      const filteredArray = arrayFromObject.filter(
        (obj) => obj.value !== undefined
      );

      console.log("filtered array", filteredArray);

      setAppliedFilters(filteredArray);
    }
  }, [extractedValues, categoryData]);

  const handleMinPriceChange = (value) => {
    console.log("min price", value);
    setMinPrice(value);
  };

  const handleMaxPriceChange = (value) => {
    setMaxPrice(value);
  };

  const handleSliderChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  function handleChange(value) {
    const obj = { name: "range", value: value };

    console.log(`Selected: ${value}`);
    if (extractedValues.range == value) {
      console.log("extracted values in search entered");
      setExtractedValues((prevValues) => ({
        ...prevValues,
        range: "", // Using square brackets to access the property dynamically
      }));
      setAppliedFilters(appliedFilters.filter((item) => item.name == "range"));
    } else {
      setExtractedValues({ ...extractedValues, range: value });

      setAppliedFilters([...appliedFilters, obj]);
    }
  }

  function handleVendorChange(value) {
    const obj = { name: "vendorId", value: value };

    console.log(`Selected: ${value}`);
    if (extractedValues.vendorId == value) {
      console.log("extracted values in search entered");
      setExtractedValues((prevValues) => ({
        ...prevValues,
        vendorId: "", // Using square brackets to access the property dynamically
      }));
      setAppliedFilters(
        appliedFilters.filter((item) => item.name == "vendorId")
      );
    } else {
      setExtractedValues({ ...extractedValues, vendorId: value });

      setAppliedFilters([...appliedFilters, obj]);
    }
  }

  console.log("extracted values in search", extractedValues);
  console.log("app filters", appliedFilters);

  const filterhandler = (name, item) => {
    console.log("function clicking", name, item.value);
    if (extractedValues[name] == item.value) {
      //if selected item already exists in extracted values
      console.log("extracted values in search entered");
      setExtractedValues((prevValues) => ({
        //remove it from extracted values
        ...prevValues,
        [name]: "", // Using square brackets to access the property dynamically
      }));
      setAppliedFilters(appliedFilters.filter((item) => item.name != name));
    } else {
      setExtractedValues({ ...extractedValues, [name]: item.value }); //otherwise add it to extracted values
      const obj = { name: name, value: item.label };
      // Filter out existing objects with the same name
      const filteredFilters = appliedFilters.filter(
        (filter) => filter.name !== obj.name //eg: when a category is selected,it stored in applied filters.now when selecting another category, the previous saved one should be removed.
      );

      // Set appliedFilters to the filtered array plus the new object
      setAppliedFilters([...filteredFilters, obj]); //now add new one to the applied filters
    }
  };

  const priceFilterHandler = (item) => {
    // const index = priceArray.find((data) => item == data);
    // if (!index) {
    //   setPriceArray([...priceArray, item]);
    // } else {
    //   setPriceArray(priceArray.filter((card) => card != item));
    // }
    setPriceValue(item);
  };

  const clearAppliedFilter = () => {
    navigate("/search");
    setAppliedFilters([]);
    setExtractedValues({});
  };

  // console.log("cat array", catArray, proximityData);
  // console.log("applied filter", appliedFilterData);

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/img/dropdown-arrow.svg"
      style={{ width: "24px", height: "24px" }}
    /> // Customize the arrow color as needed
  );

  // const getClassName = () => {
  //   if (windowWidth < 900) {
  //     return isVisible ? 'filterOuter-slider' : 'filterOuter';
  //   } else {
  //     return 'filterOuter';
  //   }
  // };

  useEffect(() => {
    console.log("entered here");
    if (isVisible) {
      setClassName("filterOuterSlider");
    } else {
      setClassName("filterOuter");
    }
    // if(appliedFilters?.length>0){
    //   setClassName("filterOuterWithFilter")

    // }
  }, [isVisible, appliedFilters]);

  console.log("category data", categoryData);

  const locationFinder = (place) => {
    console.log("selected place", place);
    if (place.geometry && place.geometry.location) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const formattedAddress = place.formatted_address;
      const placeName = place.address_components[0]?.long_name;

      setLocationSelected({
        latitude: latitude,
        longitude: longitude,
        address: formattedAddress,
        place: placeName,
      });

      dispatch(
        filterActions.togglelocation({
          latitude: latitude,
          longitude: longitude,
          address: formattedAddress,
          place: placeName,
        })
      );

    }
  };

  return (
    <div ref={myDivRef} className={className}>
      <div className={classes.closeSymbolDiv}>
        <img
          onClick={() => setIsVisible(!isVisible)}
          src="/img/icons/close-icon-red.svg"
        />
      </div>
      <div className={classes.appliedFilters}>
        <div className={classes.filterTitle}>
          <h1>Applied Filters</h1>
          <h4 onClick={clearAppliedFilter}>CLEAR</h4>
        </div>
        <div className={classes.filterFlex}>
          {appliedFilters?.map((item) => (
            <div className={classes.filterContainer}>
              <h5>{item.value}</h5>
              <img
                onClick={() => removeFilter(item.name, item.value)}
                src="/img/icons/crossmark.svg"
              />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.categoriesFilter}>
        <h1>Categories</h1>
        <Scrollbars
          style={{ width: "100%", height: 122 }}
          autoHide // This prop hides the scrollbar when not scrolling
        >
          <div className={classes.categoryCheck}>
            {/* {categoryDatas.map((item) => (
              <div className={classes.categoryDiv}>
                <img
                  src={
                    catArray.find((data) => {
                      console.log("item values", item, data);
                      return item == data;
                    })
                      ? "/img/icons/checkboxRed.svg"
                      : "/img/icons/checkboxEmpty.svg"
                  }
                  onClick={() => filterhandler(item)}
                />
                <h4>{item}</h4>
              </div>
            ))} */}
            {categoryData.map((item) => (
              <div className={classes.categoryDiv}>
                <img
                  src={
                    extractedValues.categoryId == item.value
                      ? "/img/icons/checkboxRed.svg"
                      : "/img/icons/checkboxEmpty.svg"
                  }
                  onClick={() => filterhandler("categoryId", item)}
                />
                <h4>{item.label}</h4>
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
      <div className={classes.proximityFilter}>
        <h2>Location</h2>
        <div className="autocomplete-div">
              <Autocomplete
                className="banner-location"
                onPlaceSelected={locationFinder}
                defaultValue={selectedAddress}
                // types={["(cities)"]} // Customize types as needed
                style={{ width: "100%", boxSizing: "border-box" }}
                // onChange={(e) => setValue(e.target.value)}
                placeholder={"Enter a Location"}
                apiKey={GOOGLE_MAP_API_KEY}
              />
              <CaretDownOutlined className={"dropdown-arrow"} />
            </div>
      </div>
      <div className={classes.proximityFilter}>
        <h2>Proximity</h2>
        <Select
          showSearch
          dropdownStyle={{ zIndex: 99999999 }}
          suffixIcon={customArrow}
          placeholder={<span style={placeholderStyle}>Select Proximity</span>}
          //   showSearch
          style={{ width: "100%", cursor: "pointer", height: "43px" }}
          optionFilterProp="children"
          onChange={handleChange}
          value={extractedValues?.range ? extractedValues?.range : undefined}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {proximityValue.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </div>

      <div className={classes.proximityFilter}>
        <h2>Vendor</h2>
        <Select
          showSearch
          dropdownStyle={{ zIndex: 99999999 }}
          suffixIcon={customArrow}
          placeholder={<span style={placeholderStyle}>Select Vendor</span>}
          //   showSearch
          style={{ width: "100%", cursor: "pointer", height: "43px" }}
          optionFilterProp="children"
          onChange={handleVendorChange}
          value={extractedValues?.vendorId}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {vendorData.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </div>

      <div className={classes.eventFilter}>
        <h2>Event type</h2>
        <Scrollbars
          style={{ width: "100%", height: 148 }}
          autoHide // This prop hides the scrollbar when not scrolling
        >
          <div className={classes.categoryCheck}>
            {eventData.map((item) => (
              <div className={classes.categoryDiv}>
                <img
                  src={
                    extractedValues.eventType == item.value
                      ? "/img/icons/checkboxRed.svg"
                      : "/img/icons/checkboxEmpty.svg"
                  }
                  onClick={() => filterhandler("eventType", item)}
                />
                <h4>{item.label}</h4>
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
      {!window.location.pathname.includes("/package-search") && (
        <div className={classes.ratingFilter}>
          <h2>Rating</h2>
          <div className={classes.categoryCheck}>
            {ratingData.map((item) => (
              <div className={classes.categoryDiv}>
                <img
                  src={
                    extractedValues.ratingId == item.value
                      ? "/img/icons/checkboxRed.svg"
                      : "/img/icons/checkboxEmpty.svg"
                  }
                  onClick={() => filterhandler("ratingId", item)}
                />
                <h4>{item.label}</h4>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchFilters;

import { useRef, useState } from "react";

import { emailIsValid } from "validators/authValidation";

import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";

import classes from "./SignupForm.module.css";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import { useDispatch } from "react-redux";

import { useMutation } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";

import useInput from "hooks/useInput";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, InputNumber, message } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SignupForm = ({ redirect = false }) => {
  const [authError, setAuthError] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [loginWithPhone, setLoginWithPhone] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  console.log("mobile number", mobileNumber);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [name, setName] = useState();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isPhoneValid, setisPhoneValid] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const { isLoading, isError, mutateAsync } = useMutation(async (userData) => {
    console.log("userData", userData);
    const response = await fetch(`${API_BASE_URL}auth/createCustomer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    console.log("response", response);
    if (!response.ok) {
      message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  });

  const [formError, setFormError] = useState(null);

  const emailInputRef = useRef();
  const acceptTermsInputRef = useRef();

  const onSumbitFormHandler = async (event) => {
    event.preventDefault();

    if (!isNameValid || !isEmailValid || !isPasswordValid || !isPhoneValid) {
      message.error("Please fill all the fields");
      return;
    }

    if (!acceptTermsInputRef.current.checked) {
      message.error("Please Accept the Terms & Conditions");
      return;
    }

    const createdUser = await mutateAsync({
      name: name,
      // type: "CUSTOMER",
      email: email,
      phoneNo: mobileNumber,
      password: password,
      status: "ACTIVE",
    });
    console.log("createdUser", createdUser);

    if (createdUser) {
      message.success("User Created Successfully.");
      dispatch(userActions.login({ user: createdUser.data }));
      if (redirect) {
        navigate("/", { replace: true });
      } else {
        dispatch(modalActions.toggleModal({ show: false }));
      }
    }

    // if (!emailIsValid(emailInputRef.current.value)) {
    //   setFormError("Email is invalid");
    //   return;
    // }

    // if (!acceptTermsInputRef.current.checked) {
    //   setFormError("Must agree");
    //   return;
    // }

    // onSumbitForm(emailInputRef.current.value);
  };

  const shiftToSignIn = () => {
    if (location.pathname.includes("signup")) {
      navigate("/signin");
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    }
  };

  const checkNameValid = () => {
    if (!name.trim()) {
      message.error("Invalid name");
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
  };

  const checkEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setIsEmailValid(false);
      message.error("Invalid Email ID");
    } else {
      setIsEmailValid(true);
    }
  };

  const checkPasswordValid = () => {
    const minLength = 8; // Minimum length requirement for the password
    const hasUpperCase = /[A-Z]/.test(password); // Check if password contains uppercase letter
    const hasLowerCase = /[a-z]/.test(password); // Check if password contains lowercase letter
    const hasNumber = /[0-9]/.test(password); // Check if password contains number
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Check if password contains special character

    if (password.length < minLength) {
      message.error("Password must be at least 8 characters long.");
      setIsPasswordValid(false);
    } else if (
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      message.error(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
  };

  const checkPhoneNumber = () => {
    console.log("mobile number inside", mobileNumber);
    const characters = mobileNumber.split("");
    const letterCount = characters?.length;
    console.log("letter count", letterCount);

    if (letterCount !== 13) {
      message.error("Phone number must be in 10 digits.");
      setisPhoneValid(false);
    } else {
      setisPhoneValid(true);
    }
  };

  return (
    <form className={classes.signUpForm} onSubmit={onSumbitFormHandler}>
      <h1>Create an account</h1>
      <h2>
        Join Us Today and Transform Your Vision into Unforgettable Events,
        Crafted with Elegance and Expertise.
      </h2>
      {/* {isLoading && <LoadingSpinner />} */}
      {authError && <ErrorLabel>{authError}</ErrorLabel>}
      <div className={classes.inputField}>
        <label>Name</label>
        <Input
          placeholder="Type Your Name"
          className="signInInput"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={checkNameValid}
        />
      </div>
      <div className={classes.inputField}>
        <label>Email</label>
        <Input
          placeholder="Type Your Email"
          className="signInInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={checkEmailValid}
        />
      </div>
      <div className={classes.inputField}>
        <label>Password</label>
        <Input.Password
          placeholder="Type Your Password"
          className="signInInput2"
          onChange={(e) => setPassword(e.target.value)}
          onBlur={checkPasswordValid}
        />
      </div>
      <div className={classes.inputField}>
        <label>Phone Number</label>
        <PhoneInput
          className="phone-ant-input"
          country="IN"
          defaultCountry="IN"
          value={mobileNumber}
          onChange={(e) => {
            console.log("phone input value", e);
            setMobileNumber(e);
          }}
          onBlur={checkPhoneNumber}
          // placeholder="Type Your Phone Number"
        />
        {/* <InputNumber
          placeholder="Type Your Phone Number"
          className="signInInput2"
          style={{ width: "100%" }}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={checkPasswordValid}
        /> */}
      </div>
      <div className={classes.signUpOptions}>
        <CheckboxInput
          id="acceptTerms"
          name="acceptTerms"
          ref={acceptTermsInputRef}
          // isCircle
          label="I agree with Terms &amp; Conditions"
        />
      </div>
      <Button
        option="colored"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
        className={classes.signupButton}
        onClick={onSumbitFormHandler}
      >
        Sign Up
      </Button>
      <section className={classes.signUpFooter}>
        <p>
          Already have an account ?
          <span className={classes.signUpLink} onClick={shiftToSignIn}>
            Sign In
          </span>
        </p>
      </section>
    </form>
  );
};

export default SignupForm;

// const SignupForm = ({ onSumbitForm }) => {
//   const [formError, setFormError] = useState(null);

//   const emailInputRef = useRef();
//   const acceptTermsInputRef = useRef();

//   const onSumbitFormHandler = (event) => {
//     event.preventDefault();

//     if (!emailIsValid(emailInputRef.current.value)) {
//       setFormError('Email is invalid');
//       return;
//     }

//     if (!acceptTermsInputRef.current.checked) {
//       setFormError('Must agree');
//       return;
//     }

//     onSumbitForm(emailInputRef.current.value);
//   };

//   return (
//     <form className={classes.signupForm} onSubmit={onSumbitFormHandler}>
//       {formError && <ErrorLabel> {formError} </ErrorLabel>}
//       <InputWithLabel
//         labelText="Email"
//         name="email"
//         type="email"
//         ref={emailInputRef}
//         icon={MailIcon}
//         required
//       />
//       <CheckboxInput
//         id="acceptTerms"
//         name="acceptTerms"
//         ref={acceptTermsInputRef}
//         isCircle
//         label="I agree with terms &amp; conditions"
//       />
//       <Button type="sumbit" option="colored" className={classes.sumbitButton}>
//         Sign Up
//       </Button>
//     </form>
//   );
// };

// export default SignupForm;

import React, { useRef } from "react";
import classes from "./PackageDeals.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PackageCard from "./PackageCard";

const packageData = [
  {
    title: "Moon Dreams Wedding",
    district: "Kochi",
    state: "Kerala",
    highlights: ["The Dream Arena", "Stella Makeup Studio Kanjangad"],
    price: 240000,
    strikePrice: 320000,
    img: "/img/packages/package_one.svg",
  },
  {
    title: "Newly Wed World Bonsai StudioNewly Wed World Bonsai Studio",
    district: "Kochi",
    state: "Kerala",
    highlights: ["The Dream Arena", "Stella Makeup Studio Kanjangad"],
    price: 240000,
    strikePrice: 320000,
    img: "/img/packages/package_two.svg",
  },
  {
    title: "Moon Dreams Wedding",
    district: "Kochi",
    state: "Kerala",
    highlights: ["The Dream Arena", "Stella Makeup Studio Kanjangad"],
    price: 240000,
    strikePrice: 320000,
    img: "/img/packages/package_two.svg",
  },
];

function PackageDeals({ containerClass }) {
  const outerContainerWidth = (88 * window.innerWidth) / 100;
  const cardWidth = 630;
  const gapBetweenCards = 15;
  const customArrow = "/img/right-arrow.svg";

  const sliderRef = useRef(null);

  const numCardsToShow =
    (outerContainerWidth + gapBetweenCards) / (cardWidth + gapBetweenCards);

  console.log("window files", numCardsToShow);

  const CustomNextArrow = ({ onClick }) => (
    <div className="custom-arrow" onClick={onClick}>
      <img src={customArrow} alt="Next" style={{ cursor: "pointer" }} />
    </div>
  );

  const handleNextArrowClick = (originalOnClick) => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numCardsToShow,
    // slidesToShow: 2.6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    margin: 15,
    //     centerMode: true, // Add centerMode
    // centerPadding: '-15px', // Adjust as needed
    centerMode: false,
    variableWidth: true,
    // nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="package-slider">
      <div className={`${classes.packageOuter} ${containerClass}`}>
        <h2 className={classes.packageTitle}>Package Deals</h2>
        <div className={classes.sliderOuter}>
          <Slider {...settings} ref={sliderRef}>
            {packageData.map((card, index) => (
              <div key={index}>
                <PackageCard data={card} />
              </div>
            ))}
          </Slider>
          <img
            className={classes.sliderArrow}
            src={customArrow}
            onClick={handleNextArrowClick}
          />
        </div>
      </div>
    </div>
  );
}

export default PackageDeals;

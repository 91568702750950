import React from "react";
import classes from "./PackageDetail.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import VenueCarousel from "../venue-content/VenueCarousel";
import PriceDetail from "./PriceDetail";
import ItemInfo from "./ItemInfo";
import PackageDeals from "../home-content/PackageDeals/PackageDeals";
import EventCard from "../search-content/EventCard";

const samplePackageDetail = {
  title: "About The Dream Arena Package",
  priceDetails: {
    price: 340000,
    strikePrice: 400000,
  },
  sampleImages: [
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
  ],
  cardsData: [
    {
      title: "The Dream Arena",
      city: "Kochi",
      state: "Kerala",
      price: 120000,
      strikePrice: 180000,
      review: 4.7,
      img: "/img/venue-banner.svg",
    },
    {
      title: "The Dream Arena Montreal Carnival",
      city: "Kochi",
      state: "Kerala",
      price: 120000,
      strikePrice: 180000,
      review: 4.7,
      img: "/img/venue-banner.svg",
    },
    {
      title: "The Dream Arena",
      city: "Kochi",
      state: "Kerala",
      price: 120000,
      strikePrice: 180000,
      review: 4.7,
      img: "/img/venue-banner.svg",
    },
    {
      title: "The Dream Arena Montreal Carnival",
      city: "Kochi",
      state: "Kerala",
      price: 120000,
      strikePrice: 180000,
      review: 4.7,
      img: "/img/venue-banner.svg",
    },
  ],
  description:
    "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur temp",
  availableDays: ["Monday", "Friday", "Saturday", "Thursday"],
  availableTimings: ["9 AM - 2 PM", "6 PM - 11 PM"],
  amenities: [
    "Outdoor Garden Oasis",
    "Decoration Packages",
    "Wedding Planning Services",
  ],
};

const PackageDetail = () => {
  const windowWidth = useWindowWidth();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.venueInfo}>
          <VenueCarousel images={samplePackageDetail?.sampleImages} />

          {/* Package name and details */}
          <ItemInfo />

          {windowWidth < 1024 && (
            // PriceCard for mobile screen
            <div className={classes.priceDetails}>
              <PriceDetail details={samplePackageDetail?.priceDetails} />
            </div>
          )}

          {/* Package description */}
          <div className={classes.description}>
            <h3 className={classes.heading}>
              About {samplePackageDetail?.title}
            </h3>
            <p className={classes.paragraph}>
              {samplePackageDetail?.description}
            </p>
          </div>

          {/* Package cards */}
          <div className={classes.packageCards}>
            <h3 className={classes.heading}>Package includes :</h3>
            <div className={classes.cardGrid}>
              {samplePackageDetail?.cardsData?.map((card) => (
                // <div className={classes.eventCardWrapper}>
                <EventCard
                  // listingId={card.listingId} //uncomment if this component is used
                  image={card.img}
                  title={card.title}
                  review={card.review}
                  city={card.city}
                  state={card.state}
                  price={card.price}
                  strikePrice={card.strikePrice}
                />
                // </div>
              ))}
            </div>
          </div>
        </div>

        {windowWidth >= 1024 && (
          // PriceCard for desktop screen
          <div className={classes.priceDetails}>
            <PriceDetail details={samplePackageDetail?.priceDetails} />
          </div>
        )}
      </div>
      {/* Package deals slider */}
      <PackageDeals containerClass={classes.packageContainer} />
    </div>
  );
};

export default PackageDetail;

import { useCallback, useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { userActions } from 'store-redux/user-slice';

import { API_BASE_URL, APP_PREFIX_PATH, GOOGLE_MAP_API_KEY } from 'configs/AppConfig';

import Layout from 'components/layout/Layout';
import DispatchSignInModal from 'hooks/DispatchSignInModal';
import LoadingSpinner from 'components/UI/loading-spinner/LoadingSpinner';
import Login from 'pages/Login';
import SignUp from 'pages/Signup';
import VenueDetail from 'components/page-content/venue-content/VenueDetail';
import PackageSearch from 'components/page-content/package-search/PackageSearch';
import WishList from 'components/page-content/WIshList/WishList';
import BookingsPage from 'components/page-content/BookingsPage/BookingsPage';
import { ConfigProvider } from 'antd';
import Gallery from 'components/page-content/gallery-content/Gallery';
import PackageDetail from 'components/page-content/package-detail-content/PackageDetail';
import Cart from 'pages/Cart';
import ProfileSettings from 'components/page-content/ProfileSettings/ProfileSettings';
import Checkout from 'components/page-content/Checkout/Checkout';
import { filterActions } from 'store-redux/filter-slice';
import SearchPage from 'components/page-content/search-content/SearchPageNew';
import { cartActions } from 'store-redux/cart-slice';
import TestData from 'components/TestData/TestData';
import { wishlistActions } from 'store-redux/wishlist-slice';
import { USER_FILTER_VALUES } from 'helpers/constants';
import PolicyDetail from 'components/page-content/policy-content/PolicyDetail';

//Define the main pages urls here
const Home = lazy(() => import(`pages/Home`));
const FAQ = lazy(() => import(`pages/FAQ`));
const PrivacyPolicy = lazy(() => import(`pages/PrivacyPolicy`));
// const AboutUs = lazy(() => import(`pages/AboutUs`));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000
    }
  }
});

const App = () => {
  // access the modalSlice State in the Store Reducer
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [checkForAuthorization, setCheckForAuthorization] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();

  //--------Use this code if there is login in the project----------------
  const autoLoginHandler = useCallback(() => {
    dispatch(userActions.autoLogin());
  }, [dispatch]);

  useEffect(() => {
    // auto login first time app is running
    autoLoginHandler();
    setCheckForAuthorization(true);
  }, [autoLoginHandler]);

  const grantLoggedInAccess = checkForAuthorization && isLoggedIn;
  const preventAccess = checkForAuthorization && !isLoggedIn;
  //----------------------------------------------------------------------


  const autoLocationHandler = useCallback(() => {
    dispatch(filterActions.autoFillLocation());
  }, [dispatch]);

  // useEffect(() => {
  //   // auto fill of location details
  //   autoLocationHandler();
  // }, [autoLocationHandler]);


//to get location from browser
  useEffect(()=>{

    const findLocation = async() => {
    const responses = await fetch(
      `https://ipapi.co/json/`
    );
    const datas = await responses.json();
    console.log('ip based', datas)

    // Convert coordinates to address using Google Maps Geocoding API
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${datas.latitude},${datas.longitude}&key=${GOOGLE_MAP_API_KEY}`
    );
    const data = await response.json();

    if (data.status === "OK") {
      const formattedAddress = data.results[0].formatted_address;
      const addressComponents = data.results[0].address_components;
      const placeName = addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name || data.results[0].formatted_address;
      if(datas?.latitude){
        dispatch(
          filterActions.togglelocation({
            latitude: datas.latitude,
            longitude: datas.longitude,
            address: `${datas.city}, ${datas?.region}, ${datas?.country_name}`,
            place: placeName
          })
        );
      }
    }

    
  }

  const storedLocation = JSON.parse(
    localStorage.getItem(USER_FILTER_VALUES)
);
  if(!storedLocation){
  findLocation()
  }else{
    dispatch(
      filterActions.togglelocation({
        latitude: storedLocation.latitude,
        longitude: storedLocation.longitude,
        address: storedLocation.address,
        place: storedLocation.place
      })
    );
  }

  },[navigator.geolocation])

  const getCartData = async () => {
    const response = await fetch(`${API_BASE_URL}cart/list/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }

    dispatch(cartActions.toggleCart({ cartTotal: data.total }))
    // return data.data;
  }

  const getWishlistData = async () => {
    const response = await fetch(`${API_BASE_URL}wishlist/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }

    console.log("wishlist data", data)
    dispatch(wishlistActions.toggleWishlist({ totalItems: data.total || data.data?.length, wishlist: data.data }))
    // return data.data;
  }

  useEffect(() => {
    if (user?.sessionToken) {
      getCartData();
      getWishlistData();
    }
  }, [user]);

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#FF6666',
          colorTextPlaceholder: "#A1A1A1"
          // borderRadius: 2,

          // Alias Token
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        {/* Defined seperate routes to avoid coming under Layout component */}
        <Routes>
          <Route
            path={`${APP_PREFIX_PATH}/login`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/signup`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SignUp />
              </Suspense>
            }
          />
        </Routes>
        <Layout
          isDashboard={
            location.pathname.includes('dashboard') ||
            location.pathname.includes('profile')
          }
          notScrollable={modalIsVisible}
        >
          {/* Define Routes and corresponding components of main pages */}
          <Routes>
            <Route
              path={`${APP_PREFIX_PATH}`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/search`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <SearchPage />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/package-search`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PackageSearch />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/wishlist/*`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <WishList />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/bookings`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <BookingsPage />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/profile`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <ProfileSettings />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/checkout`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Checkout />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/faq`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <FAQ />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/information-policy/:infoSlug`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PolicyDetail />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/venues/venue/:venueId`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <VenueDetail />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/venues/venue/:venueId/gallery`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Gallery />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/packages/package`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <PackageDetail />
                </Suspense>
              }
            />
            <Route
              path={`${APP_PREFIX_PATH}/cart`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Cart />
                </Suspense>
              }
            />

            {/* Redirect Route */}
            {/* {checkForAuthorization && (
            <Route
              path="*"
              element={<Navigate to={`${APP_PREFIX_PATH}`} replace />}
            />
          )} */}
            {/* <Route
              path={`${APP_PREFIX_PATH}/test`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <TestData />
                </Suspense>
              }
            /> */}
          </Routes>
        </Layout>
      </QueryClientProvider >
    </ConfigProvider>
  );
};

export default App;

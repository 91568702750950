import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal } from "antd";

const bookingsData = {
  "2024-03-30": ["Booking 1", "Booking 2"], // Example bookings for March 30, 2024
  // Add more booking details for other dates as needed
};

function TestData() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  console.log('selected date is', selectedDate)

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventClick={function (arg) {
          // alert(arg.event.title)
          // alert(arg.event.start)
          setSelectedDate(arg.event);
          setModalVisible(true);
        }}
        events={[
          { title: "event 1", date: "2024-03-14", details: "details of first booking",name: "MNZ Auditorium" },
          { title: "event 2", date: "2024-03-14" },
          { title: "event 2", date: "2024-03-14" },
          { title: "event 2", date: "2024-03-25" },
          { title: "event 2", date: "2024-03-20" },
          { title: "event 2", date: "2024-03-30" },
          { title: "event 5", date: "2024-04-05", details: "details of first booking",name: "MNZ Auditorium" },
          { title: "event 5", date: "2024-04-05", details: "details of first booking",name: "MNZ Auditorium" },
          { title: "event 5", date: "2024-04-05", details: "details of first booking",name: "MNZ Auditorium" },
          { title: "event 5", date: "2024-04-05", details: "details of first booking",name: "MNZ Auditorium" },
          { title: "event 5", date: "2024-04-05", details: "details of first booking",name: "MNZ Auditorium" },
          
        ]}
      />
      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedDate?.title && <p>Title: {selectedDate?.title}</p>}
      {selectedDate?._def && selectedDate?._def.extendedProps && (
        <p>Details: {selectedDate?._def.extendedProps.details}</p>
      )}
      {selectedDate?._def && selectedDate?._def.extendedProps && (
        <p>Name: {selectedDate?._def.extendedProps.name}</p>
      )}
      </Modal>
    </div>
  );
}

export default TestData;

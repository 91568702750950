
export const ConstantsDataKey = 'constants';
export const SettingsDataKey = 'settings';
export const BannersDataKey = 'banners';
export const VenueDetailDataKey = 'venueDetail';
export const CartDataKey = 'cart';
export const WishlistDataKey = 'cart';
export const ProfileDataKey = 'profile';


export const StatesDateKey = 'states';
export const CitiesDateKey = 'cities';

export const PublicInformation = 'publicInformation';

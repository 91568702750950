import React, { useState } from "react";
import classes from "./PackageSearch.module.css";
import PackageFilters from "./package-filter/PackageFilter";
import SearchFilters from "../search-content/Search-filters/SearchFilters";
import PackageCards from "./Packagecards/PackageCards";
import CustomPagination from "components/CustomPagination/CustomPagination";

function PackageSearch() {
  const [appliedFilterData, setAppliedFilterData] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [eventArray, setEventArray] = useState([]);
  const [ratingArray, setRatingArray] = useState([]);
  const [priceArray, setPriceArray] = useState([]);
  const [proximityData, setProximitydata] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [capacityData, setCapacityData] = useState(null);

  console.log('is visible', isVisible)

  const removeFilter = (item) => {
    const catFinder = catArray.find((data) => item == data);
    const proxFinder = proximityData == item;
    const eventFinder = eventArray.find((data) => item == data);
    const ratingFinder = ratingArray.find((data) => item == data);
    const priceFinder = priceValue == item;
    const capacityFinder = capacityData == item;

    console.log("remove data", catFinder, proxFinder, item, catArray);

    if (catFinder) {
      setCatArray(catArray.filter((data) => item != data));
      setAppliedFilterData(appliedFilterData.filter((card) => card != item));
    } else if (proxFinder) {
      setProximitydata(null);
      setAppliedFilterData(appliedFilterData.filter((data) => data != item));
    }else if (capacityFinder) {
        setCapacityData(null);
        setAppliedFilterData(appliedFilterData.filter((data) => data != item));
      }
     else if (eventFinder) {
      setEventArray(eventArray.filter((data) => item != data));
      setAppliedFilterData(appliedFilterData.filter((card) => card != item));
    } else if (ratingFinder) {
      setRatingArray(ratingArray.filter((data) => item != data));
      setAppliedFilterData(appliedFilterData.filter((card) => card != item));
    } else if (priceFinder) {
      // setPriceArray(priceArray.filter((data) => item != data));
      // setAppliedFilterData(appliedFilterData.filter((card) => card != item));
      setPriceValue(null);
      setAppliedFilterData(appliedFilterData.filter((card) => card != item));
    }
  };

  return (
    <>
    <div className={classes.searchOuter}>

        <SearchFilters
          appliedFilterData={appliedFilterData}
          setAppliedFilterData={setAppliedFilterData}
          removeFilter={removeFilter}
          catArray = {catArray}
          setCatArray = {setCatArray}
          eventArray = {eventArray}
          setEventArray = {setEventArray}
          ratingArray = {ratingArray}
          setRatingArray = {setRatingArray}
          proximityData = {proximityData}
          setProximitydata = {setProximitydata}
          priceValue = {priceValue}
          setPriceValue = {setPriceValue}
          isVisible = {isVisible}
          setIsVisible = {setIsVisible}
          capacityData = {capacityData}
          setCapacityData = {setCapacityData}
        />

      <PackageCards
        appliedFilterData={appliedFilterData}
        setAppliedFilterData={setAppliedFilterData}
        removeFilter={removeFilter}
        isVisible = {isVisible}
        setIsVisible = {setIsVisible}
      />
    </div>
    
   </>
  );
}

export default PackageSearch;

import React, { useEffect } from "react";
import classes from "./styles/Signup.module.css";
import SignInForm from "components/forms/sign-in/SignInForm";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useWindowWidth from "helpers/useWindowWidth";
import SignupForm from "components/forms/sign-up/SignupForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const width = useWindowWidth();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.formWrapper}>
          <SignupForm redirect={true} />
        </div>
      </div>
      {width > 767 && (
        <div className={classes.imageContainer}>
          <img
            src="/img/signup-wall.png"
            alt="wallpaper"
            className={classes.image}
          />
        </div>
      )}
    </div>
  );
};

export default SignUp;

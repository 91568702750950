import React from "react";
import classes from "./ItemInfo.module.css";
import MapIconGray from "components/UI/icons/MapIconGray";
import WishlistIcon from "components/UI/icons/WishlistIcon";
import ShareIcon from "components/UI/icons/ShareIcon";
import StarRatings from "react-star-ratings";

const ItemInfo = () => {
  return (
    <div className={classes.infoContainer}>
      <div className={classes.leftSection}>
        <h1 className={classes.name}>The Dream Arena , Kochi</h1>
        <div className={classes.cityWrapper}>
          <MapIconGray className={classes.cityIcon} />
          Kochi , Kerala
        </div>
      </div>
      <div className={classes.rightSection}>
        <div className={classes.iconWrapper}>
          <WishlistIcon />
          <ShareIcon />
        </div>
      </div>
    </div>
  );
};

export default ItemInfo;

import classes from './Backdrop.module.css';

const Backdrop = ({onClose, transparent}) => {
  return (
    <div
      className={`${classes.backdrop} ${transparent && classes.transparent}`}
      onClick={onClose}
    ></div>
  );
};

export default Backdrop;

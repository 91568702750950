import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { modalActions } from "store-redux/modal-slice";
import { API_BASE_URL } from "configs/AppConfig";

import { confirmPasswordIsValid } from "validators/authValidation";

import Button from "components/UI/button/Button";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import MailIcon from "components/UI/icons/MailIcon";
import PasswordIcon from "components/UI/icons/PasswordIcon";
import ProfileIcon from "components/UI/icons/ProfileIcon";
import ConfirmPasswordIcon from "components/UI/icons/ConfirmPasswordIcon";
import { ErrorMessage } from "@hookform/error-message";

import classes from "./SignupDetailsForm.module.css";

const validation = {
  required: {
    required: "Please fill the required fields",
  },
  password: {
    required: "Please fill the required fields",
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/,
      message:
        "Password is too weak. Password must have atleast:- 1 Upper & 1 Lower Character, 1 Special Character, 1 Number. Min Length 8.",
    },
  },
};

let errorTimer = null;

const SignupDetailsForm = ({ emailValue }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
  } = useForm();

  const acceptTermsInputRef = useRef();

  const { isLoading, isError, mutateAsync } = useMutation(async (userData) => {
    const response = await fetch(`${API_BASE_URL}customer/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    if (!response.ok) {
      setError("acceptTerms", {
        type: "required",
        message: data.message,
      });
      throw new Error(data.message);
    }

    return data;
  });

  const onSubmitFormHandler = async (data, event) => {
    if (!acceptTermsInputRef.current.checked) {
      setError("acceptTerms", {
        type: "required",
        message: "Must accept terms",
      });
      return;
    }

    await mutateAsync({
      name: `${data.firstName} ${data.lastName}`,
      email: emailValue,
      password: data.password,
      contact: data.contact,
      contactPerson: {
        name: "string",
        phone: "9123456789",
      },
      gst: false,
      hdfcPanValidation: false,
      panNumber: "string",
      pcc: false,
      permanentAddress: {
        address: "100 City Name",
        city: "string",
        pincode: "string",
        state: "string",
      },
      officeAddress: {
        address: "100 City Name",
        city: "string",
        pincode: "string",
        state: "string",
      },
    });

    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  useEffect(() => {
    if (errors) {
      errorTimer = setTimeout(() => clearErrors(), [5000]);
    }

    return () => clearTimeout(errorTimer);
  }, [errors]);

  return (
    <form
      className={classes.signupDetailsForm}
      onSubmit={handleSubmit(onSubmitFormHandler)}
    >
      <ErrorMessage
        errors={errors}
        name={`${Object.keys(errors)[0]}`}
        render={({ message }) => (
          <ErrorLabel>
            {message ? message : "Confirm password not match"}
          </ErrorLabel>
        )}
      />
      <InputWithLabel
        labelText="Email"
        name="email"
        type="email"
        icon={MailIcon}
        value={emailValue}
        disabled
      />
      <section className={classes.row}>
        <InputWithLabel
          labelText="First name"
          name="firstName"
          type="text"
          icon={ProfileIcon}
          register={register}
          validation={validation.required}
        />
        <InputWithLabel
          labelText="Last name"
          name="lastName"
          type="text"
          icon={ProfileIcon}
          register={register}
          validation={validation.required}
        />
      </section>
      <InputWithLabel
        labelText="Password"
        name="password"
        type="password"
        icon={PasswordIcon}
        register={register}
        validation={validation.password}
      />
      <InputWithLabel
        labelText="Confirm password"
        name="confirmPassword"
        type="password"
        icon={ConfirmPasswordIcon}
        register={register}
        validation={{
          validate: (value) =>
            confirmPasswordIsValid(value, getValues("password")),
        }}
      />
      <InputWithLabel
        labelText="Contact"
        name="contact"
        type="number"
        icon={ProfileIcon}
        register={register}
        validation={validation.required}
      />
      <CheckboxInput
        id="acceptTerms"
        name="acceptTerms"
        ref={acceptTermsInputRef}
        isCircle
        label="I agree with terms &amp; conditions"
      />
      <Button type="submit" option="colored" className={classes.sumbitButton}>
        Continue
      </Button>
    </form>
  );
};

export default SignupDetailsForm;

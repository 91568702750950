import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useMutation } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";

import useInput from "hooks/useInput";
import { notEmptyIsValid } from "validators/authValidation";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import PasswordIcon from "components/UI/icons/PasswordIcon";
import PasswordEyeIcon from "components/UI/icons/PasswordEyeIcon";
import PasswordEyeOffIcon from "components/UI/icons/PasswordEyeOffIcon";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

import classes from "./SignInForm.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Input, message } from "antd";

const SignInForm = ({ redirect = false }) => {
  const [authError, setAuthError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [authinfo, setAuthinfo] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const resetPasswordClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "resetpassword",
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setAuthError(null);
    }, 2000);
  }, [authError]);

  const { isLoading, isError, mutateAsync } = useMutation(async (userData) => {
    console.log("userData", userData);
    const response = await fetch(`${API_BASE_URL}auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    console.log("response", response);
    if (!response.ok) {
      message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setAuthError(null);

    if (!authinfo || !password) {
      // setAuthError("Please fill the required");
      message.error("Please fill Email and Password");
      return;
    }
    console.log("authInfo", authinfo, password);

    // send request to the API with email & password
    // if response correct
    const retrievedUser = await mutateAsync({
      authinfo: authinfo.trim().replace(/\s/g, ""),
      password: password.trim().replace(/\s/g, ""),
    });
    console.log("retrievedUser", retrievedUser);

    // const rememberMe = e.target.rememberMe.checked;

    if (retrievedUser.data.user?.type !== "CUSTOMER") {
      message.error("Invalid login type");
      // authinfoResetHandler();
      // passwordResetHandler();
      return;
    }

    dispatch(userActions.login({ user: retrievedUser.data }));
    dispatch(modalActions.toggleModal({ show: false }));
    //to navigate to dashboard after signin
    if (redirect) navigate(`/`, { replace: true });
  };

  const shiftToSignup = () => {
    if (location.pathname.includes("login")) {
      navigate("/signup");
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signup",
        })
      );
    }
  };

  return (
    <form className={classes.signinForm} onSubmit={(e) => onSubmitHandler(e)}>
      <h1>Welcome Back !</h1>
      <h2>
        Log in to Your Account and Unlock the Door to Seamless Event Planning
        and Timeless Celebrations.
      </h2>
      {isLoading && <LoadingSpinner />}
      {authError && <ErrorLabel>{authError}</ErrorLabel>}
      <div className={classes.inputField}>
        <label>Email</label>
        <Input
          placeholder="Type Your Email"
          className="signInInput"
          value={authinfo}
          onChange={(e) => setAuthinfo(e.target.value)}
        />
      </div>
      <div className={classes.inputField}>
        <label>Password</label>
        {/* <Input placeholder="Type Your Password" className="signInInput" /> */}
        <Input.Password
          // type={passwordType}
          visibilityToggle={{
            visible: passwordVisible,
            onVisibleChange: setPasswordVisible,
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Type Your Password"
          className="signInInput2"
        />
        {/* <Input.Password
          placeholder="Type Your Password"
          className="signInInput"
        /> */}
      </div>
      <div className={classes.signinOptions}>
        <CheckboxInput
          id="showPassword"
          name="showPassword"
          label="Show Password"
          // isCircle
          onClick={() => setPasswordVisible((prevState) => !prevState)}
        />
        <span
          className={classes.recoverPassword}
          onClick={resetPasswordClickHandler}
        >
          Forgot Password?
        </span>
      </div>
      <Button
        option="colored"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
        className={classes.loginButton}
        onClick={() => onSubmitHandler()}
      >
        Log In
      </Button>
      {/* <p className={classes.orText}>Or</p> */}
      {/* <div
        className={classes.changeLoginType}
        onClick={() => {
          setLoginWithPhone((presValue) => !presValue);
        }}
      >
        {loginWithPhone
          ? "Sign in with Username/E-mail"
          : "Sign in with Phone Number"}
      </div> */}
      <section className={classes.signinFooter}>
        <p>
          Don't have an account ?
          <span className={classes.signUpLink} onClick={shiftToSignup}>
            Sign Up
          </span>
        </p>
      </section>
    </form>
  );
};

export default SignInForm;

import { useDispatch } from 'react-redux';
import { modalActions } from 'store-redux/modal-slice';

import Modal from 'components/UI/modal/Modal';
import ModalHeader from 'components/UI/modal/ModalHeader';
import SignupDetailsForm from 'components/forms/sign-up/SignupDetailsForm';

import classes from './SignupDetails.module.css';

const SignupDetails = ({ onClose, emailValue }) => {
  const dispatch = useDispatch();
  const onBackClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: 'signup'
      })
    );
  };

  return (
    <Modal onClose={onClose} className={classes.modal}>
      <div className={classes.signupDetailsMain}>
        <ModalHeader
          onClose={onClose}
          title="Sign Up"
          onBack={onBackClickHandler}
        />
        <section className={classes.signupDetailsBody}>
          <SignupDetailsForm emailValue={emailValue} />
        </section>
      </div>
    </Modal>
  );
};

export default SignupDetails;

import React, { forwardRef } from "react";

import classes from "./CheckboxInput.module.css";
import { Checkbox } from "antd";

const CheckboxInput = forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      isCircle,
      onClick,
      required,
      type,
      onChange,
      checked,
      defaultChecked,
      register,
      validation,
      labelClass,
      checkboxClass,
    },
    ref
  ) => {
    const reg = register ? { ...register(name, { ...validation }) } : null;
    return (
      <div className={`${classes.wrapper} ${isCircle ? classes.circle : ""}`}>
        <input
          onClick={onClick}
          id={id}
          name={name}
          type={type ? type : "checkbox"}
          required={required}
          ref={ref}
          onChange={onChange}
          checked={checked}
          value={value}
          defaultChecked={defaultChecked}
          className={checkboxClass}
          {...reg}
        />
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      </div>
    );
  }
);

export default CheckboxInput;

import React from "react";
import classes from "./PackageCard.module.css";
import { Button } from "antd";

function PackageCard({ data }) {
  return (
    <div className={classes.packageOuter}>
      <div className={classes.packageContent}>
        <h2>{data.title}</h2>
        <div className={classes.packageLocation}>
          <img src="/img/packages/location_icon.svg" />
          <p>
            {data.district},{data.state}
          </p>
        </div>
        <ul>
          {data.highlights.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
        <div className = {classes.priceSection}>
        <Button className={classes.priceButton}>₹{data.price.toLocaleString('en-IN')}</Button>
        <p>₹{data.strikePrice.toLocaleString('en-IN')}</p>
        </div>
      </div>
      <div className={classes.packageImage}>
        <img src={data.img} />
      </div>
    </div>
  );
}

export default PackageCard;

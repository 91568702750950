import { createSlice } from '@reduxjs/toolkit';
import { USER_FILTER_VALUES } from 'helpers/constants';

const initialState = {
    totalItems: null,
    wishlist: [],
};

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        toggleWishlist(state, action) {
            if (action.payload.totalItems) {
                state.totalItems = action.payload.totalItems;
            }
            if (action.payload.wishlist) {
                state.wishlist = action.payload.wishlist;
            }
            // const cartJson = JSON.stringify(state);
            // save the user data and expiration time in local storage
            // localStorage.setItem(USER_FILTER_VALUES, filterJson);
        },
        emptyWishlist(state) {
            state.totalItems = 0;
            state.wishlist = [];
        },
    }
});

export const wishlistActions = wishlistSlice.actions;
export default wishlistSlice.reducer;

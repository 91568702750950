import classes from "./HumburgerButton.module.css";

const HumburgerButton = ({ open, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${classes.container} ${open && classes.change} ${className}`}
    >
      {/* <div className={classes.bar1}></div>
      <div className={classes.bar2}></div>
      <div className={classes.bar3}></div> */}
      <img src="/img/icons/humburger.svg" alt="hum" />
    </div>
  );
};

export default HumburgerButton;

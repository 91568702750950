import React from 'react';

const CloseIcon = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="34" height="34" viewBox="0 0 34 34" fill="none">
      <g opacity="0.8" clip-path="url(#clip0_551_33100)">
        <circle cx="17" cy="17" r="17" fill="black" />
        <path d="M23.2983 10.7102C23.2058 10.6175 23.0959 10.544 22.9749 10.4938C22.8539 10.4436 22.7242 10.4178 22.5933 10.4178C22.4623 10.4178 22.3326 10.4436 22.2116 10.4938C22.0907 10.544 21.9808 10.6175 21.8883 10.7102L16.9983 15.5902L12.1083 10.7002C12.0157 10.6076 11.9058 10.5342 11.7848 10.4841C11.6638 10.434 11.5342 10.4082 11.4033 10.4082C11.2723 10.4082 11.1427 10.434 11.0217 10.4841C10.9008 10.5342 10.7909 10.6076 10.6983 10.7002C10.6057 10.7928 10.5322 10.9027 10.4821 11.0237C10.432 11.1446 10.4062 11.2743 10.4062 11.4052C10.4063 11.5362 10.432 11.6658 10.4821 11.7868C10.5322 11.9077 10.6057 12.0176 10.6983 12.1102L15.5883 17.0002L10.6983 21.8902C10.6057 21.9828 10.5322 22.0927 10.4821 22.2137C10.432 22.3346 10.4062 22.4643 10.4062 22.5952C10.4062 22.7262 10.432 22.8558 10.4821 22.9768C10.5322 23.0977 10.6057 23.2076 10.6983 23.3002C10.7909 23.3928 10.9008 23.4662 11.0217 23.5163C11.1427 23.5665 11.2723 23.5922 11.4033 23.5922C11.5342 23.5922 11.6638 23.5665 11.7848 23.5163C11.9058 23.4662 12.0157 23.3928 12.1083 23.3002L16.9983 18.4102L21.8883 23.3002C21.9809 23.3928 22.0908 23.4662 22.2117 23.5163C22.3327 23.5665 22.4623 23.5922 22.5933 23.5922C22.7242 23.5922 22.8538 23.5665 22.9748 23.5163C23.0958 23.4662 23.2057 23.3928 23.2983 23.3002C23.3909 23.2076 23.4643 23.0977 23.5144 22.9768C23.5645 22.8558 23.5903 22.7262 23.5903 22.5952C23.5903 22.4643 23.5645 22.3346 23.5144 22.2137C23.4643 22.0927 23.3909 21.9828 23.2983 21.8902L18.4083 17.0002L23.2983 12.1102C23.6783 11.7302 23.6783 11.0902 23.2983 10.7102Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_551_33100">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
    // <svg
    //   className={className}
    //   width="14"
    //   height="14"
    //   viewBox="0 0 14 14"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M13.4023 1.88672L8.28906 7L13.4023 12.1133L12.1133 13.4023L7 8.28906L1.88672 13.4023L0.597656 12.1133L5.71094 7L0.597656 1.88672L1.88672 0.597656L7 5.71094L12.1133 0.597656L13.4023 1.88672Z"
    //     fill="#0D0A19"
    //   />
    // </svg>
  );
};

export default CloseIcon;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useMutation } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";
import { modalActions } from "store-redux/modal-slice";

import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";

import useInput from "hooks/useInput";
import { emailIsValid, notEmptyIsValid } from "validators/authValidation";
import InputWithLabel from "components/UI/forms/input/InputWithLabel";
import Button from "components/UI/button/Button";
import MailIcon from "components/UI/icons/MailIcon";
import PasswordIcon from "components/UI/icons/PasswordIcon";
import PasswordEyeIcon from "components/UI/icons/PasswordEyeIcon";
import PasswordEyeOffIcon from "components/UI/icons/PasswordEyeOffIcon";
import CheckboxInput from "components/UI/forms/input/CheckboxInput";
import ErrorLabel from "components/UI/forms/errors/ErrorLabel";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

import classes from "./ResetPasswordForm.module.css";
import { useNavigate } from "react-router-dom";
import PasswordLockIcon from "components/UI/icons/PasswordLockIcon";
import { ErrorMessage } from "@hookform/error-message";

let errorTimer = null;
let successTimer = null;

const ResetPasswordForm = () => {
  const [authError, setAuthError] = useState(null);
  const [authSuccess, setAuthSuccess] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  // const errorsLength = Object.keys(errors).length;
  useEffect(() => {
    // if (errorsLength > 0) {
    if (authError) {
      errorTimer = setTimeout(() => setAuthError(null), [5000]);
    }

    return () => clearTimeout(errorTimer);
  }, [authError]);

  useEffect(() => {
    if (authSuccess) {
      successTimer = setTimeout(() => setAuthSuccess(null), [5000]);
    }

    return () => clearTimeout(successTimer);
  }, [authSuccess]);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const resetPasswordClickHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "resetpassword",
      })
    );
  };

  const { isLoading, isError, mutateAsync } = useMutation(async (userData) => {
    const response = await fetch(`${API_BASE_URL}buyer/forgot-password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    if (!response.ok) {
      setAuthError("E-mail not registered");
      throw new Error(data.message);
    }

    return data;
  });

  const {
    inputValue: email,
    inputValueIsValid: emailValueIsValid,
    inputHasAnError: emailHasError,
    onChangeInputValueHandler: emailChangeHandler,
    onBlurInputHanlder: emailBlurHandler,
    onResetInputHandler: emailResetHandler,
  } = useInput(emailIsValid);

  useEffect(() => {
    console.log("email error", emailHasError);
    if (emailHasError) {
      setAuthError("Enter a valid E-mail");
    } else setAuthError(null);
  }, [emailHasError]);

  const {
    inputValue: password,
    inputValueIsValid: passwordValueIsValid,
    inputHasAnError: passwordHasError,
    onChangeInputValueHandler: passwordChangeHandler,
    onBlurInputHanlder: passwordBlurHandler,
    onResetInputHandler: passwordResetHandler,
  } = useInput(notEmptyIsValid);

  const {
    inputValue: confirmPassword,
    inputValueIsValid: confirmPasswordValueIsValid,
    inputHasAnError: confirmPasswordHasError,
    onChangeInputValueHandler: confirmPasswordChangeHandler,
    onBlurInputHanlder: confirmPasswordBlurHandler,
    onResetInputHandler: confirmPasswordResetHandler,
  } = useInput(notEmptyIsValid);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (!emailValueIsValid) {
      setAuthError("Please fill the required");
      return;
    }

    // send request to the API with email & password
    // if response correct
    const retrievedUser = await mutateAsync({
      authInfo: email,
    });

    console.log("response forgot password", retrievedUser);
    if (retrievedUser?.success) {
      setAuthSuccess("Sent Reset Password link");
    }

    // const rememberMe = e.target.rememberMe.checked;

    // if (
    //   retrievedUser.data.AuthType !== 'Participant' ||
    //   retrievedUser.data.user?.participantType === 'Seller'
    // ) {
    //   setAuthError('Invalid login type');
    //   emailResetHandler();
    //   passwordResetHandler();
    //   return;
    // }

    // dispatch(
    //   userActions.login({ user: retrievedUser.data, remember: rememberMe })
    // );
    // dispatch(modalActions.toggleModal({ show: false }));
    // // to navigate to dashboard after signin
    // navigate(`${APP_PREFIX_PATH}/dashboard`, { replace: true })
  };

  return (
    <form className={classes.resetForm} onSubmit={(e) => onSubmitHandler(e)}>
      {isLoading && <LoadingSpinner />}
      {authError && <ErrorLabel>{authError}</ErrorLabel>}
      {authError && (
        <ErrorMessage
          errors={authError}
          name="ErrorInput"
          // name={`${Object.keys(errors)[0]}`}
          render={({ message }) => <ErrorLabel>{message}</ErrorLabel>}
        />
      )}
      {authSuccess && <ErrorLabel option="green">{authSuccess}</ErrorLabel>}
      <div className={classes.icon}>
        <PasswordLockIcon />
      </div>
      <InputWithLabel
        value={email}
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        onReset={emailResetHandler}
        labelText="Enter Registered E-mail"
        name="email"
        type="email"
        // type="text"
        icon={MailIcon}
        required
      />
      {/* <InputWithLabel
        value={password}
        onChange={passwordChangeHandler}
        onBlur={passwordBlurHandler}
        onReset={passwordResetHandler}
        labelText="New Password"
        name="password"
        type={passwordType}
        icon={passwordType === 'password' ? PasswordEyeOffIcon : PasswordEyeIcon}
        togglePassword={togglePassword}
        required
      /> */}
      {/* <InputWithLabel
        value={confirmPassword}
        onChange={confirmPasswordChangeHandler}
        onBlur={confirmPasswordBlurHandler}
        onReset={confirmPasswordResetHandler}
        labelText="Confirm New Password"
        name="password"
        type={confirmPasswordType}
        icon={confirmPasswordType === 'password' ? PasswordEyeOffIcon : PasswordEyeIcon}
        togglePassword={toggleConfirmPassword}
        required
      /> */}
      {/* <div className={classes.signinOptions}>
        <CheckboxInput
          id="rememberMe"
          name="rememberMe"
          label="Remember me"
          isCircle
        />
        <span className={classes.recoverPassword}
        onClick={resetPasswordClickHandler}
        >Forgot Password?</span>
      </div> */}
      <Button
        option="colored"
        className={classes.sumbitButton}
        loading={isLoading}
        disabled={isLoading}
      >
        Submit
      </Button>
    </form>
  );
};

export default ResetPasswordForm;

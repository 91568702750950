import React from 'react';

const PlayButton = ({ className, onClick }) => {
    return (
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <circle opacity="0.67" cx="37.1129" cy="36.9079" r="36.5172" fill="white" />
            <path opacity="0.67" d="M52.8184 34.4785C54.6908 35.5595 54.6908 38.262 52.8184 39.343L32.8076 50.8963C30.9352 51.9773 28.5948 50.626 28.5948 48.464L28.5948 25.3575C28.5948 23.1955 30.9352 21.8442 32.8076 22.9252L52.8184 34.4785Z" fill="#E64298" />
        </svg>

    );
};

export default PlayButton;
import { useDispatch, useSelector } from "react-redux";

import { modalActions } from "store-redux/modal-slice";
import { NavbarLinks } from "helpers/constants";

import InputForm from "components/forms/input/InputForm";
import HumburgerButton from "components/UI/button/HumburgerButton";
import Signup from "components/modal/sign-up/Signup";
import SignupDetails from "components/modal/sign-up/SignupDetails";
import SignIn from "components/modal/sign-in/SignIn";
import Button from "components/UI/button/Button";

import NavbarList from "./navbar-list/NavbarList";
import NavbarLogo from "./navbar-logo/NavbarLogo";
import SideNavbar from "./side-navbar/SideNavbar";
import NavbarListIcon from "./navbar-list-icon/NavbarListIcon";

import classes from "./Navbar.module.css";
import ResetPassword from "components/modal/reset-password/ResetPassword";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowWidth from "helpers/useWindowWidth";
import Backdrop from "components/UI/modal/Backdrop";
import { Select } from "antd";
import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";

// Navbar component => the Parent Component for the Navbar elements
// all the navbar related components are child for this component
let emailValue = "";
const { Option } = Select;

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const location = useSelector((state) => state.filter);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [filterData, setFilterData] = useState({
    // latitude: "",
    // longitude: "",
    range: undefined,
    categoryId: undefined,
  });

  const [eventData, setEventData] = useState([]);

  const locationValue = useLocation();

  // useEffect(()=>{
  // const filterValues = JSON.parse(localStorage.getItem("wedding_filter"))
  // console.log('filter from local', filterValues)
  // setFilterData(filterValues);
  // },[])

  console.log("location in navbar", location);

  const proximityValues = [
    { label: "Within 5 km", value: 5 },
    { label: "Within 10 km", value: 10 },
    { label: "Within 20 km", value: 20 },
    { label: "Within 50 km", value: 50 },
    { label: "Within 75 km", value: 75 },
  ];

  useEffect(() => {
    fetchcategories();
  },[isSearchOpen]);

  useEffect(()=>{
    if(locationValue.pathname.includes('/search')){
      setIsSearchOpen(true)
    }else{
      setIsSearchOpen(false)
    }
  },[locationValue.pathname])

  const width = useWindowWidth();
  const isBigScreen = width > 1024;

  //----------Login modals and buttons in navbar --------
  // const setSigninModalHandler = () => {
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: 'signin'
  //     })
  //   );
  // };

  // const setSignupDetailsModalHandler = (value) => {
  //   emailValue = value;
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: "signupDetails",
  //     })
  //   );
  // };

  // const renderBrowserNavButtons = () => {
  //   if (!isLoggedIn) {
  //     return (
  //       <Button type="button" option="colored" onClick={setSigninModalHandler}>
  //         Sign in
  //       </Button>
  //     );
  //   }

  //   return <NavbarListIcon setSigninModalHandler={setSigninModalHandler} />;
  // };

  const setSideNavbarIsOpenHandler = () => {
    if (modalType === "sidenav") {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "sidenav",
        })
      );
    }
  };

  const closeModalHandler = () => {
    if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  //----------Modal components corresponding to required functionality------
  const renderModal = () => {
    if (modalIsVisible) {
      if (modalType === "signup") {
        return (
          <Signup
            // showSignupDetailsModal={setSignupDetailsModalHandler}
            onClose={toggleModalHandler}
          />
        );
      }

      if (modalType === "signupDetails") {
        return (
          <SignupDetails emailValue={emailValue} onClose={toggleModalHandler} />
        );
      }

      if (modalType === "signin") {
        return <SignIn onClose={toggleModalHandler} />;
      }

      if (modalType === "resetpassword") {
        return <ResetPassword onClose={toggleModalHandler} />;
      }

      if (modalType === "sidenav" && !isBigScreen) {
        return (
          <>
            <SideNavbar onClose={toggleModalHandler} />
            <Backdrop onClose={toggleModalHandler} />
          </>
        );
      }
    }

    return;
  };

  const searchIconFunction = () => {
    if (width >= 1020) {
      setIsSearchOpen(true);
    } else {
      navigate(`${APP_PREFIX_PATH}/search`);
    }
  };

  const constructQueryString = (filterData) => {
    const queryParams = [];
    for (const key in filterData) {
      if (filterData[key] !== "" && filterData[key] !== undefined) {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(filterData[key])}`
        );
      }
    }
    return queryParams.join("&");
  };

  // useEffect(()=>{
  //   if(window.location.pathname.includes('/search')){
  //     console.log('entered here')
  //   handleClick();
  //   }

  // },[location])

  const handleClick = () => {
    const queryString = constructQueryString(filterData);
    const baseUrl = "http://localhost:3000/search"; // Replace 'your_base_url_here' with your actual base URL
    const finalUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;
    localStorage.setItem("wedding_filter", JSON.stringify(filterData));
    if(window.location.pathname.includes('/search')){
      navigate(`search/?${queryString}`)
    }else{
    navigate(`/search?${queryString}`)
    }
  };
  
  const fetchEvents = async () => {
    const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setEventData(
      data.data
    );
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  useEffect(() => {
    fetchEvents();
  }, [user]);

  const fetchcategories = async () => {
    const response = await fetch(`${API_BASE_URL}category/get/all/admin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setCategoryData(
      data.data.map((item) => ({ label: item.name, value: item._id }))
    );
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  // useEffect(() => {
  //  if(!window.location.pathname.includes('/search')){
  //   setFilterData({
  //     range: undefined,
  //     categoryId: undefined,
  //   })
  //  }
  // }, [window.location.pathname]);

  const filterDataChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  console.log("filter data in navbar", filterData);

  return (
    <div className={`${classes.mainNavbar}`}>
      <div className={classes.navbarGroup}>
        {!isBigScreen && (
          <HumburgerButton
            className={classes.humburgerButton}
            onClick={setSideNavbarIsOpenHandler}
            open={modalIsVisible && modalType === "sidenav"}
          />
        )}
        <NavbarLogo onClick={closeModalHandler} />
      </div>
      <div className={classes.navbarList}>
        <NavbarList
          navList={NavbarLinks}
          isSearchOpen={isSearchOpen}
          proximityValues={proximityValues}
          filterDataChange={filterDataChange}
          categoryData={categoryData}
          filterData = {filterData}
          eventData = {eventData}
        />
      </div>
      {!isSearchOpen && (
        <div className={classes.searchIcon} onClick={searchIconFunction}>
          <img src="/img/icons/searchIcon.svg" alt="search" />
        </div>
      )}
      {isSearchOpen && (
        <div className={classes.searchOpen}>
          <Button
            option="colored"
            className={classes.searchButton}
            onClick={handleClick}
          >
            <img src="/img/icons/search.svg" alt="search" />
            Search
          </Button>
          <img
            src="/img/icons/close-cross.svg"
            alt="close"
            onClick={() => setIsSearchOpen(false)}
          />
        </div>
      )}

      {renderModal()}
    </div>
  );
};

export default Navbar;

const ProfileIcon = ({ className }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.80859 9.35547C4.19141 8.79297 5.51562 8.51172 6.78125 8.51172C8.04688 8.51172 9.35938 8.79297 10.7188 9.35547C12.1016 9.89453 12.793 10.6094 12.793 11.5V13.0117H0.769531V11.5C0.769531 10.6094 1.44922 9.89453 2.80859 9.35547ZM8.89062 6.12109C8.30469 6.70703 7.60156 7 6.78125 7C5.96094 7 5.25781 6.70703 4.67188 6.12109C4.08594 5.53516 3.79297 4.83203 3.79297 4.01172C3.79297 3.19141 4.08594 2.48828 4.67188 1.90234C5.25781 1.29297 5.96094 0.988281 6.78125 0.988281C7.60156 0.988281 8.30469 1.29297 8.89062 1.90234C9.47656 2.48828 9.76953 3.19141 9.76953 4.01172C9.76953 4.83203 9.47656 5.53516 8.89062 6.12109Z"
        fill="#9A9EA7"
      />
    </svg>
  );
};

export default ProfileIcon;

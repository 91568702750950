import React, { Component } from 'react';

import classes from './LoadingSpinner.module.css';
import { Spin } from 'antd';

class LoadingSpinner extends Component {
  render() {
    return (
      <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%"
          }} />
    );
  }
}

export default React.memo(LoadingSpinner);

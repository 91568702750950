import React from "react";
import classes from "./DetailGallery.module.css";
import { Tabs } from "antd";
import GalleryGrid from "./GalleryGrid";

const DetailGallery = ({ images, videos, venueId }) => {
  return (
    <div className={classes.galleryContainer}>
      <Tabs
        defaultActiveKey="1"
        // onChange={onChange}
        className="venueTabs" //antd css changes are defined in index.css
        size="large"
        items={[
          {
            label: `Photos`,
            key: "1",
            children: (
              <GalleryGrid images={images} type="photo" venueId={venueId} />
            ),
          },
          {
            label: `Videos`,
            key: "2",
            children: (
              <GalleryGrid videos={videos} type="video" venueId={venueId} />
            ),
          },
        ]}
      />
    </div>
  );
};

export default DetailGallery;

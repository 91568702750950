import React from 'react';

const MapIconGray = ({ className, onClick }) => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <path d="M6.49479 5.75C6.13564 5.75 5.79121 5.6183 5.53725 5.38388C5.2833 5.14946 5.14062 4.83152 5.14062 4.5C5.14062 4.16848 5.2833 3.85054 5.53725 3.61612C5.79121 3.3817 6.13564 3.25 6.49479 3.25C6.85394 3.25 7.19838 3.3817 7.45233 3.61612C7.70629 3.85054 7.84896 4.16848 7.84896 4.5C7.84896 4.66415 7.81393 4.8267 7.74588 4.97835C7.67782 5.13001 7.57808 5.26781 7.45233 5.38388C7.32659 5.49996 7.1773 5.59203 7.01301 5.65485C6.84871 5.71767 6.67262 5.75 6.49479 5.75ZM6.49479 1C5.48918 1 4.52475 1.36875 3.81368 2.02513C3.1026 2.6815 2.70313 3.57174 2.70312 4.5C2.70313 7.125 6.49479 11 6.49479 11C6.49479 11 10.2865 7.125 10.2865 4.5C10.2865 3.57174 9.88698 2.6815 9.1759 2.02513C8.46483 1.36875 7.5004 1 6.49479 1Z" fill="#5D5C5C" />
        </svg>
    );
};

export default MapIconGray;
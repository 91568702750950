import { forwardRef } from 'react';

import classes from './SelectInput.module.css';

const SelectInput = forwardRef(
  (
    {
      onChange,
      options,
      name,
      value = undefined,
      className,
      register,
      validation
    },
    ref
  ) => {
    const reg = register ? { ...register(name, { ...validation }) } : null;

    return (
      <select
        name={name}
        ref={ref}
        onChange={onChange}
        className={`${classes.selectInput} ${className}`}
        {...reg}
        value={value}
      >
        {options.map((opt, index) => (
          <option value={opt} key={opt + index}>
            {opt}
          </option>
        ))}
      </select>
    );
  }
);

export default SelectInput;

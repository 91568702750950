import { createSlice } from '@reduxjs/toolkit';
import { USER_FILTER_VALUES } from 'helpers/constants';

const initialState = {
    cartTotal: null,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        toggleCart(state, action) {
            if (action.payload.cartTotal) {
                state.cartTotal = action.payload.cartTotal;
            }
            // const cartJson = JSON.stringify(state);
            // save the user data and expiration time in local storage
            // localStorage.setItem(USER_FILTER_VALUES, filterJson);
        },
        emptyCart(state) {
            state.cartTotal = 0;
        },
    }
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;

import React from 'react';

const LeftArrowBlack = ({ className, onClick }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <circle opacity="0.6" cx="26" cy="26" r="26" fill="black" />
            <path d="M30.0608 16.4548C30.342 16.7361 30.5 17.1176 30.5 17.5153C30.5 17.9131 30.342 18.2945 30.0608 18.5758L22.6358 26.0008L30.0608 33.4258C30.3341 33.7087 30.4852 34.0876 30.4818 34.4809C30.4784 34.8742 30.3207 35.2504 30.0425 35.5285C29.7644 35.8066 29.3882 35.9644 28.9949 35.9678C28.6016 35.9712 28.2227 35.82 27.9398 35.5468L19.4543 27.0613C19.1731 26.78 19.0151 26.3986 19.0151 26.0008C19.0151 25.6031 19.1731 25.2216 19.4543 24.9403L27.9398 16.4548C28.2211 16.1736 28.6026 16.0156 29.0003 16.0156C29.3981 16.0156 29.7795 16.1736 30.0608 16.4548Z" fill="white" />
        </svg>
    );
};

export default LeftArrowBlack;
import { createPortal } from "react-dom";

import NavbarList from "../navbar-list/NavbarList";
import InputForm from "components/forms/input/InputForm";

import classes from "./SideNavbar.module.css";
import Backdrop from "components/UI/modal/Backdrop";
import SideNavbarList from "./SideNavbarList";

const SideNavbar = ({ onClose }) => {
  const portalElement = document.getElementById("overlays");
  const onSubmitSearchFormHandler = (value) => {};

  return createPortal(
    <div className={classes.sideNavbar}>
      {/* <InputForm
        name="search"
        placeholder="Search"
        onSubmit={onSubmitSearchFormHandler}
        className={classes.searchInput}
      /> */}
      <SideNavbarList onClose={onClose} />
      {/* <NavbarList isSide onClose={onClose} className={classes.navbarList} /> */}
    </div>,
    portalElement
  );
};

export default SideNavbar;

import React, { useState } from "react";
import classes from "./WishListCards.module.css";
import searchData from "../search-content/SearchData";
import WeddingPagination from "components/WeddingPagination/WeddingPagination";
import EventCard from "../search-content/EventCard";
import { API_BASE_URL } from "configs/AppConfig";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { WishlistDataKey } from "helpers/queriesDataKeys";
import { wishlistActions } from "store-redux/wishlist-slice";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { Link } from "react-router-dom";

function WishListCards({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  isVisible,
  setIsVisible,
}) {
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { wishlist: wishlistData } = useSelector((state) => state.wishlist);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsCountPerPage = 20;
  const totalItemsCount = 400;

  const {
    isLoading,
    data,
    refetch: refetchWishlist,
  } = useQuery([WishlistDataKey, user], async () => {
    const response = await fetch(`${API_BASE_URL}wishlist/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // setWishlistData([]);
      // message.error(data.message);
      throw new Error(data.message);
    }

    dispatch(
      wishlistActions.toggleWishlist({
        totalItems: data.total || data.data?.length,
        wishlist: data.data,
      })
    );
    // setWishlistData(data.data);
    return data.data;
  });
  console.log("wishlist data from api", wishlistData);

  return (
    <div className={classes.cardsOuter}>
      {!wishlistData?.length > 0 && isLoading && <LoadingSpinner />}
      {!wishlistData?.length > 0 && !isLoading && (
        <h3 className={classes.noItems}>No Items added in your Wishlist</h3>
      )}
      {wishlistData?.length > 0 && (
        <div className={classes.cardFlex}>
          {wishlistData?.map((card) => (
            <div className={classes.eventCardWrapper}>
              <Link to={`/venues/venue/${card.listingId}`}>
                <EventCard
                  listingId={card.listingId}
                  isWishlist={true}
                  image={card.listingImages[0]}
                  title={card.listingName}
                  // review={card.review}
                  city={card.listingLocation?.locationName}
                  // state={card.state}
                  // price={card.price}
                  // strikePrice={card.strikePrice}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
      {/* <WeddingPagination
        setCurrentPage={setCurrentPage}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={itemsCountPerPage}
      /> */}
    </div>
  );
}

export default WishListCards;

import React from 'react'

const SecurityIcon = ({ className, onClick }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
            <mask id="mask0_659_3865" maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="20">
                <path d="M2.5 3.44L10.0038 1.25L17.5 3.44V7.93083C17.5 10.2324 16.7757 12.4755 15.4298 14.3426C14.084 16.2096 12.1848 17.6058 10.0012 18.3333C7.81707 17.6059 5.9172 16.2095 4.57088 14.3421C3.22455 12.4746 2.50003 10.2309 2.5 7.92875V3.44Z" fill="#555555" stroke="white" stroke-width="2.5" stroke-linejoin="round" />
            </mask>
            <g mask="url(#mask0_659_3865)">
                <path d="M0 0H20V20H0V0Z" fill="#AFACAC" />
            </g>
            <g clip-path="url(#clip0_659_3865)">
                <path d="M12.5749 5.88062L9.24254 10.2741L7.27237 8.30533L6.36328 9.21442L9.39311 12.2443L13.636 6.78971L12.5749 5.88062Z" fill="#AFACAC" />
            </g>
            <defs>
                <clipPath id="clip0_659_3865">
                    <rect width="7.27273" height="7.27273" fill="white" transform="translate(6.36328 5.45459)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SecurityIcon
import React, { useState } from "react";
import classes from "./PackageCards.module.css";
import packageData from "../PackageData";
import PackageCard from "../Packagecard";
import WeddingPagination from "components/WeddingPagination/WeddingPagination";

function PackageCards({
  appliedFilterData,
  setAppliedFilterData,
  removeFilter,
  isVisible,
  setIsVisible,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsCountPerPage = 20;
  const totalItemsCount = 400;

  console.log("current page", currentPage);

  return (
    <>
      <div className={classes.cardsOuter}>
        <div className={classes.appliedFilters}>
          <button onClick={() => setIsVisible(!isVisible)}>Filter</button>
          <div className={classes.filterFlex}>
            {appliedFilterData?.map((item) => (
              <div className={classes.filterContainer}>
                <h5>{item}</h5>
                <img
                  onClick={() => removeFilter(item)}
                  src="/img/icons/crossmark.svg"
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.cardFlex}>
          {packageData.map((card) => (
            <div className={classes.eventCardWrapper}>
              <PackageCard
                image={card.img}
                title={card.title}
                review={card.review}
                city={card.city}
                state={card.state}
                price={card.price}
                strikePrice={card.strikePrice}
                details={card.details}
              />
            </div>
          ))}
        </div>
        {/* <CustomPagination
     currentPage={currentPage}
     totalPage={totalPage}
     setCurrentPage = {setCurrentPage}
  
   /> */}
        <WeddingPagination
          setCurrentPage={setCurrentPage}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={itemsCountPerPage}
        />
      </div>
    </>
  );
}

export default PackageCards;
